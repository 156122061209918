import { curveInit } from "./Constants";

export function createSelectedDraw({ scene }) {
  // lots of math in here just to match final scale...
  let curveInitString = "";
  for (let [key, value] of Object.entries(curveInit)) {
    curveInitString += "uniform ";
    if (typeof value === "object") {
      if (value.length === 2) {
        curveInitString += "vec2 ";
      } else if (value.length === 3) {
        curveInitString += "vec3 ";
      }
    } else {
      curveInitString += "float ";
    }
    curveInitString += key;
    curveInitString += ";\n";
  }

  let regl = scene.regl;
  let params = {
    vert: `
        precision highp float;

        uniform mat4 viewProjection;
        uniform vec2 aspect;
        uniform float size;
        uniform vec2 selectedStart;
        uniform vec2 selectedEnd;
        uniform float coordTransition;

        attribute vec2 position;

        attribute vec2 uv;
        varying vec2 vuv;

        ${curveInitString}

        void main () {
          vuv = uv;
          // magic number
          float scale = size * 37.5;

          vec2 coord = mix(selectedStart, selectedEnd, coordTransition);

          vec3 final = vec3(coord, 0) + vec3(position, 0) * vec3(aspect, 0) * scale;
          gl_Position = viewProjection * vec4(final, 1);
        }
      `,
    frag: `
        precision mediump float;
        
        uniform sampler2D texture;
        
        varying vec2 vTexCoords;
        varying vec2 vuv;

        void main () {
          vec2 position = vuv;
         gl_FragColor = texture2D(texture, position);
        }
      `,
    attributes: {
      position: [
        [0.5, 0.5],
        [-0.5, 0.5],
        [0.5, -0.5],
        [-0.5, -0.5],
      ],
      uv: [1, 0, 0, 0, 1, 1, 0, 1],
    },
    uniforms: {
      texture: regl.prop("texture"),
      viewProjection: regl.prop("viewProjection"),
      zoomScale: regl.prop("zoomScale"),
      size: regl.prop("size"),
      selectedStart: regl.prop("selectedStart"),
      selectedEnd: regl.prop("selectedEnd"),
      coordTransition: regl.prop("coordTransition"),
      aspect: regl.prop("aspect"),
      ...curveInit,
    },
    primitive: "triangle strip",
    count: 4,
  };

  return regl(params);
}
