import { centerXYPoints, collisionSpread, getSizeKey } from "./Utils";
import { initDrawSize } from "./Constants";

export function updateCoords({
  coords,
  keys,
  scene_ref,
  userData,
  setUserData,
  coordType,
}) {
  let scene = scene_ref.current;
  // let coords3d = coords.map((c) => {
  //   if (c.length < 3) {
  //     return [...c, 0];
  //   } else {
  //     return c;
  //   }
  // });

  // coords3d = centerXYPoints(coords3d);

  // if (coordType === "grid") {
  //   // rescale grid
  //   let nearestX = Infinity;
  //   let checkPoint = coords3d[0];
  //   let drawSize = initDrawSize;
  //   for (let point of coords3d.slice(1)) {
  //     // only look in same row
  //     if (point[1] === checkPoint[1]) {
  //       let xDist = Math.abs(point[0] - checkPoint[0]);
  //       if (xDist < nearestX) nearestX = xDist;
  //     }
  //   }
  //   // add padding
  //   let rescale = (drawSize + 0.05) / nearestX;
  //   coords3d = coords3d.map((p) => {
  //     return [p[0] * rescale, p[1] * rescale, p[2]];
  //   });
  // } else if (coordType === "aran") {
  //   // // hardcoded magic scale number based on trial and error
  //   // let rescale = 0.014;
  //   // coords3d = coords3d.map((p) => {
  //   //   return [p[0] * rescale, p[1] * rescale, p[2]];
  //   // });
  // } else if (coordType === "aranCollision") {
  //   // hardcoded magic scale number based on trial and error
  //   // let rescale = 0.014;
  //   // coords3d = coords3d.map((p) => {
  //   //   return [p[0] * rescale, p[1] * rescale, p[2]];
  //   // });
  //   // coords3d = collisionSpread(coords3d);
  // } else {
  //   coords3d = collisionSpread(coords3d);
  // }

  scene.coords = coords;
  // newUserData.originalImages = keys;
  // console.log("user data updated");
  // setUserData(newUserData);

  for (let size of scene.textureSizes) {
    let sizeKey = getSizeKey(size);
    let sizeMeta = scene.drawMeta[sizeKey];
    for (let i = 0; i < sizeMeta.length; i++) {
      let meta = sizeMeta[i];
      let textureKeys = meta.keys;
      let newCoords = textureKeys.map((key) => {
        let coordIndex = keys.indexOf(key);
        return coords[coordIndex];
      });
      meta.startCoordsBuffer = meta.startCoordsBuffer(meta.coords);
      meta.endCoordsBuffer = meta.endCoordsBuffer(newCoords);
      meta.coords = newCoords;
    }
  }
  // run transition
  scene.transitionProgress = 0;
}
