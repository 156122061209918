import { useContext, useEffect, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { aUserData, sZoomCamera, aClicked, aCamera } from "./State";
import { updateHover } from "./PointerUtils";
import { zoomCamera, panCamera } from "./Utils";
import VisualizationContext from "../VisualizationContext";

function Keyboard({ scene_ref }) {
  let userData = useRecoilValue(aUserData);
  let setClicked = useSetRecoilState(aClicked);
  let setCamera = useSetRecoilState(aCamera);
  const context = useContext(VisualizationContext);

  let km_ref = useRef({});

  useEffect(() => {
    let scene = scene_ref.current;
    let km = km_ref.current;

    function pressed(press, e) {
      if (press === "=" || press === "+") {
        let sign = -1;
        let mult = 1.125;
        zoomCamera({ position: scene.pointer.position, scene, sign, mult });
      }
      if (press === "-") {
        let sign = 1;
        let mult = 1.125;
        zoomCamera({ position: scene.pointer.position, scene, sign, mult });
      }

      // arrow pan, they are organized this way so multiple keys can be pressed at the same time
      let arrowMove = false;
      let move = [0, 0];
      let dist = 48;
      if (km.arrowleft) {
        move[0] += dist;
        arrowMove = true;
      }
      if (km.arrowright) {
        move[0] -= dist;
        arrowMove = true;
      }
      if (km.arrowup) {
        move[1] += dist;
        arrowMove = true;
      }
      if (km.arrowdown) {
        move[1] -= dist;
        arrowMove = true;
      }
      if (arrowMove) {
        if (e.ctrlKey) {
          // zoom in or our if control plus arrowup or arrowdown
          if (move[1] !== 0) {
            let sign = -1;
            if (move[1] < 0) sign = 1;
            let mult = 1.125;
            zoomCamera({ position: scene.pointer.position, scene, sign, mult });
            move[1] = 0;
          }
        }
        panCamera({ scene, diff: move, updateHoverCallback: true });
        updateHover(scene);
      }
    }

    function downHandler(e) {
      let press = e.key.toLowerCase();
      km[press] = true;
      pressed(press, e);
    }

    function upHandler(e) {
      let press = e.key.toLowerCase();
      km[press] = false;
    }

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  return null;
}

export default Keyboard;
