import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  paper: {
    background: '#222',
    color: '#fff',
    paddingBottom: '15px',
    borderRadius: 0,
  },
});

function SimpleDialog({ open, text }) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
    >
      <DialogContent>
        <div>{text}</div>
      </DialogContent>
    </Dialog>
  );
}

export default SimpleDialog;
