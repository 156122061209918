import { createContext, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

export const config = {
  apiKey: 'AIzaSyDLkgYc7hkp_c4BHJ4m9ni3yTotU_GAddg',
  projectId: 'soot-90d51',
  databaseURL: 'DATABASE_URL',
  authDomain: 'AUTH_DOMAIN',
  // OPTIONAL
  storageBucket: 'soot-90d51.appspot.com',
  messagingSenderId: '218282307014',
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();

export const firebaseAuth = createContext();

export const AuthProvider = (props) => {
  const [currentUser, setCurrentUser] = useState();

  firebase.auth().onAuthStateChanged(function (user) {
    setCurrentUser(user);
  });

  const signin = async (email, password, errorOut) => {
    await auth.signInWithEmailAndPassword(email, password).catch((err) => {
      errorOut(err.message);
    });
  };

  const signout = async (email, password) => {
    await auth.signOut().catch((err) => {
      console.error(err);
    });
  };

  return (
    <firebaseAuth.Provider
      value={{
        currentUserDisplayName: currentUser ? currentUser.email : null,
        currentUserKey: currentUser ? currentUser.uid : null,
        isUserAuthenticated: !!currentUser,
        handleSignin: signin,
        handleSignout: signout,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
