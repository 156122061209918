import { curveInit } from "./Constants";

export function createDraw({
  num,
  scene,
  aspects,
  texCoords,
  texSize,
  texture,
  indexes,
}) {
  let regl = scene.regl;

  let curveInitString = "";
  for (let [key, value] of Object.entries(curveInit)) {
    curveInitString += "uniform ";
    if (typeof value === "object") {
      if (value.length === 2) {
        curveInitString += "vec2 ";
      } else if (value.length === 3) {
        curveInitString += "vec3 ";
      }
    } else {
      curveInitString += "float ";
    }
    curveInitString += key;
    curveInitString += ";\n";
  }

  const params = {
    vert: `
        precision highp float;

        // basics 
        attribute vec2 position;
        uniform mat4 viewProjection;
        uniform float zoomScale;
        uniform float size;

        // coords
        attribute vec3 startCoords;
        attribute vec3 endCoords;

        // indexes
        attribute float indexes;

        // texture and fragment stuff
        attribute vec2 texSize;
        attribute vec2 aspects;
        attribute vec2 texCoords;
        varying vec2 vTexCoords;
        attribute vec2 uv;
        varying vec2 vuv;

        uniform vec2 oRay;

        uniform vec4 selectedStart;
        uniform vec4 selectedEnd;

        uniform float coordTransition;
        uniform float selectTransition;
        uniform float distortionActive;

        ${curveInitString}

        void main () {
          vuv = uv * texSize;
          vTexCoords = texCoords;

          // base coordinates and size
          vec3 coord = mix(startCoords, endCoords, coordTransition);
          float scaleSize = size;

          float rayLimit = 4.4;
          vec2 ray = vec2(max(-rayLimit, min(rayLimit, oRay.x)), max(-rayLimit, min(rayLimit, oRay.y)));

          // start blob ---
          vec3 blobCoord = coord;
          float blobScale = 1.0;
          {
            vec2 diff = vec2(coord.x - ray.x, coord.y - ray.y);
            float dist = sqrt(diff.x * diff.x + diff.y * diff.y);
            float theta = atan(diff.y, diff.x);

            float radius = curveRadius;
            if (dist < radius) {
              vec2 a = curvePointA;
              vec2 b = curvePointB;
              vec2 c = curvePointC;

              float x = dist;
              float qa = a.x - 2.0 * b.x + c.x;
              float qb = 2.0 * (b.x - a.x);
              float qc = a.x - x;

              float t = (-qb + sqrt(pow(qb, 2.0) - 4.0 * qa * qc)) / (2.0 * qa);
              float y = (a.y - 2.0 * b.y + c.y) * pow(t, 2.0) + 2.0 * (b.y - a.y) * t + a.y;

              blobCoord.x = ray.x + cos(theta) * y;
              blobCoord.y = ray.y + sin(theta) * y;

              vec2 derivative = 2.0 * (1.0 - t) * (b - a) + 2.0 * (c - b) * t;
              float slope = derivative.y / derivative.x;
              if (slope > maxMagnify) {
                blobScale = maxMagnify + (slope - maxMagnify) / 2.0; 
              } else {
                blobScale = slope; 
              }
            }
          }
          // end blob ---

          float selectMagnify = 3.75;

          // start selectedStart ---
          vec3 unselectedStartCoord = coord;
          float selectedStartScale = 1.0;
          {
            vec2 diff = vec2(coord.x - selectedStart.x, coord.y - selectedStart.y);
            float dist = sqrt(diff.x * diff.x + diff.y * diff.y);
            float theta = atan(diff.y, diff.x);

            float radius = curveRadius;
            if (dist < radius) {
              vec2 a = curvePointA;
              vec2 b = curvePointB;
              vec2 c = curvePointC;

              float x = dist;
              float qa = a.x - 2.0 * b.x + c.x;
              float qb = 2.0 * (b.x - a.x);
              float qc = a.x - x;

              float t = (-qb + sqrt(pow(qb, 2.0) - 4.0 * qa * qc)) / (2.0 * qa);
              float y = (a.y - 2.0 * b.y + c.y) * pow(t, 2.0) + 2.0 * (b.y - a.y) * t + a.y;

              unselectedStartCoord.x = selectedStart.x + cos(theta) * y;
              unselectedStartCoord.y = selectedStart.y + sin(theta) * y;

              vec2 derivative = 2.0 * (1.0 - t) * (b - a) + 2.0 * (c - b) * t;
              float slope = derivative.y / derivative.x;
              if (slope > maxMagnify) {
                selectedStartScale = maxMagnify + (slope - maxMagnify) / 2.0; 
              } else {
                selectedStartScale = slope; 
              }

              // check if this is selected
              if (selectedStart.w == indexes) {
                selectedStartScale *= selectMagnify; 
                unselectedStartCoord.x = selectedStart.x; 
                unselectedStartCoord.y = selectedStart.y;
              } else {
                vec2 diff = vec2(unselectedStartCoord.x - selectedStart.x, unselectedStartCoord.y - selectedStart.y);
                float theta = atan(diff.y, diff.x);
                float dist = sqrt(diff.x * diff.x + diff.y * diff.y);
                unselectedStartCoord.x = selectedStart.x + cos(theta) * (dist + spread); 
                unselectedStartCoord.y = selectedStart.y + sin(theta) * (dist + spread);
              }
            }
          }
          // end select ---
          
          // start selectedEnd ---
          vec3 unselectedEndCoord = coord;
          float selectedEndScale = 1.0;
          {
            vec2 diff = vec2(coord.x - selectedEnd.x, coord.y - selectedEnd.y);
            float dist = sqrt(diff.x * diff.x + diff.y * diff.y);
            float theta = atan(diff.y, diff.x);

            float radius = curveRadius;
            if (dist < radius) {
              vec2 a = curvePointA;
              vec2 b = curvePointB;
              vec2 c = curvePointC;

              float x = dist;
              float qa = a.x - 2.0 * b.x + c.x;
              float qb = 2.0 * (b.x - a.x);
              float qc = a.x - x;

              float t = (-qb + sqrt(pow(qb, 2.0) - 4.0 * qa * qc)) / (2.0 * qa);
              float y = (a.y - 2.0 * b.y + c.y) * pow(t, 2.0) + 2.0 * (b.y - a.y) * t + a.y;

              unselectedEndCoord.x = selectedEnd.x + cos(theta) * y;
              unselectedEndCoord.y = selectedEnd.y + sin(theta) * y;

              vec2 derivative = 2.0 * (1.0 - t) * (b - a) + 2.0 * (c - b) * t;
              float slope = derivative.y / derivative.x;
              if (slope > maxMagnify) {
                selectedEndScale = maxMagnify + (slope - maxMagnify) / 2.0; 
              } else {
                selectedEndScale = slope; 
              }

              // check if this is selected
              if (selectedEnd.w == indexes) {
                selectedEndScale *= selectMagnify; 
                unselectedEndCoord.x = selectedEnd.x; 
                unselectedEndCoord.y = selectedEnd.y;
              } else {
                vec2 diff = vec2(unselectedEndCoord.x - selectedEnd.x, unselectedEndCoord.y - selectedEnd.y);
                float theta = atan(diff.y, diff.x);
                float dist = sqrt(diff.x * diff.x + diff.y * diff.y);
                unselectedEndCoord.x = selectedEnd.x + cos(theta) * (dist + spread); 
                unselectedEndCoord.y = selectedEnd.y + sin(theta) * (dist + spread);
              }
            }
          }
          // end select ---


          vec3 finalCoord = coord;
          finalCoord = blobCoord;
          scaleSize = size * blobScale;
          if (selectedStart.w != -1.0 && selectedEnd.w != -1.0) {
            // transition between selected 
            if (selectedStart.w == selectedEnd.w) {
              if (selectedEnd.w == indexes) {
                scaleSize = size * selectMagnify;
                finalCoord = mix(unselectedStartCoord, unselectedEndCoord, coordTransition);
              } else {
                finalCoord = mix(unselectedStartCoord, unselectedEndCoord, coordTransition);
                scaleSize = mix(size * selectedStartScale, size * selectedEndScale, coordTransition);
              }
            } else {
                finalCoord = mix(unselectedStartCoord, unselectedEndCoord, selectTransition);
                scaleSize = mix(size * selectedStartScale, size * selectedEndScale, selectTransition);
 
           }
          } else if (selectedStart.w == -1.0 && selectedEnd.w != -1.0) {
            // transtion from none to selected
            finalCoord = mix(blobCoord, unselectedEndCoord, selectTransition);
            scaleSize = mix(size * blobScale, size * selectedEndScale, selectTransition);
          } else if (selectedStart.w != -1.0 && selectedEnd.w == -1.0) {
            // transtion from selected to none
            finalCoord = mix(unselectedStartCoord, blobCoord, selectTransition);
            scaleSize = mix(size * selectedStartScale, size * blobScale, selectTransition);
          }

          // make into square, any magnify needs to be done after this
          vec2 sized = position * scaleSize * aspects;
          // set zoom
          vec3 final = finalCoord + vec3(sized, 0) * zoomScale;
          gl_Position = viewProjection * vec4(final, 1.0);
        }
      `,
    frag: `
        precision mediump float;
        
        uniform sampler2D texture;
        
        varying vec2 vTexCoords;
        varying vec2 vuv;

        void main () {
          vec2 position = vuv + vTexCoords;
          gl_FragColor = texture2D(texture, position);
        }
      `,
    attributes: {
      position: [
        [0.5, 0.5],
        [-0.5, 0.5],
        [0.5, -0.5],
        [-0.5, -0.5],
      ],
      uv: [1, 0, 0, 0, 1, 1, 0, 1],
      texCoords: {
        buffer: texCoords,
        divisor: 1,
      },
      texSize: {
        buffer: texSize,
        divisor: 1,
      },
      aspects: {
        buffer: aspects,
        divisor: 1,
      },
      indexes: {
        buffer: indexes,
        divisor: 1,
      },
      startCoords: {
        buffer: regl.prop("startCoords"),
        divisor: 1,
      },
      endCoords: {
        buffer: regl.prop("endCoords"),
        divisor: 1,
      },
    },
    uniforms: {
      texture: texture,
      viewProjection: regl.prop("viewProjection"),
      size: regl.prop("size"),
      zoomScale: regl.prop("zoomScale"),
      oRay: regl.prop("ray"),
      selectedStart: regl.prop("selectedStart"),
      selectedEnd: regl.prop("selectedEnd"),
      coordTransition: regl.prop("coordTransition"),
      selectTransition: regl.prop("selectTransition"),
      distortionActive: regl.prop("distortionActive"),
      ...curveInit,
    },
    primitive: "triangle strip",
    count: 4,
    instances: num,
  };

  return regl(params);
}
