import { castRay } from "./Mat4";
import { quadtree } from "d3-quadtree";
import { getSizeKey } from "./Utils";
import { curveInit } from "./Constants";
import { loadSelectedImage } from "./Utils";

export function getInvertPosition(scene) {
  // assume we're working off window size
  let x, y;
  if (scene.pointers.length > 0) {
    x = window.innerWidth / 2;
    y = window.innerHeight / 2;
  } else {
    x = scene.mousePointer[0];
    y = window.innerHeight - scene.mousePointer[1];
  }
  return [x, y];
}

export function getRay(scene) {
  let invertPosition = getInvertPosition(scene);
  return castRay(scene, invertPosition, 0).slice(0, 2);
}

export function updateHover(scene) {
  let rawRay = getRay(scene);
  scene.ray = rawRay;
}

export function arrayToVector(a) {
  return { x: a[0], y: a[1] };
}

export function selectTransform({ scene }) {
  let { sqrt, pow, cos, sin, atan2 } = Math;
  let selectedCoord = arrayToVector(scene.selectedEnd);
  let maxMagnify = curveInit.maxMagnify;
  let scales = [];
  let points = scene.coords.map((p, i) => {
    // mirror shader transforms so coords line up
    let selectScale = 1;
    let point = p.slice();
    let newx = point[0];
    let newy = point[1];
    let diffx = newx - selectedCoord.x;
    let diffy = newy - selectedCoord.y;
    let dist = sqrt(diffx * diffx + diffy * diffy);
    let theta = atan2(diffy, diffx);

    let a = arrayToVector(curveInit.curvePointA);
    let b = arrayToVector(curveInit.curvePointB);
    let c = arrayToVector(curveInit.curvePointC);

    let x = dist;
    let qa = a.x - 2.0 * b.x + c.x;
    let qb = 2.0 * (b.x - a.x);
    let qc = a.x - x;

    let t = (-qb + sqrt(pow(qb, 2.0) - 4.0 * qa * qc)) / (2.0 * qa);
    let y = (a.y - 2.0 * b.y + c.y) * pow(t, 2.0) + 2.0 * (b.y - a.y) * t + a.y;

    let derivativeX = 2.0 * (1.0 - t) * (b.x - a.x) + 2.0 * (c.x - b.x) * t;
    let derivativeY = 2.0 * (1.0 - t) * (b.y - a.y) + 2.0 * (c.y - b.y) * t;

    let slope = derivativeY / derivativeX;
    if (slope > maxMagnify) {
      selectScale = maxMagnify + (slope - maxMagnify) / 2.0;
    } else {
      selectScale = slope;
    }

    newx = selectedCoord.x + cos(theta) * y;
    newy = selectedCoord.y + sin(theta) * y;

    let spread = curveInit.spread;
    if (selectedCoord.x === point[0] && selectedCoord.y === point[1]) {
      newx = selectedCoord.x;
      newy = selectedCoord.y;
      selectScale *= 0.8;
    } else {
      // spread
      diffx = newx - selectedCoord.x;
      diffy = newy - selectedCoord.y;
      let theta = atan2(diffy, diffx);
      dist = sqrt(diffx * diffx + diffy * diffy);
      newx = selectedCoord.x + Math.cos(theta) * (dist + spread);
      newy = selectedCoord.y + Math.sin(theta) * (dist + spread);
    }
    point[0] = newx;
    point[1] = newy;
    scales.push(0.15 * selectScale * 0.5);
    return point;
  });
  scene.selectedTransformed = points;
  scene.selectedSizeAdjustments = scales;
}

export function selectImage(scene, index, userData) {
  if (scene.selectedEnd[3] !== -1) {
    // we set selectedEnd to transition to new selected
    // selectedStart is set to previous selectedEnd so it can transition
    scene.selectedStart = scene.selectedEnd.slice();
    scene.selectedEnd = [...scene.coords[index], index];
  } else {
    // if no currently selected, we transiton from none selected to new selected
    scene.selectedStart = [0, 0, 0, -1];
    scene.selectedEnd = [...scene.coords[index], index];
  }
  let sizeKey = getSizeKey(scene.textureSizes[0]);
  let lookup = scene.lookups[sizeKey][index];
  let aspect = scene.drawMeta[sizeKey][lookup[0]].aspects[lookup[1]];
  scene.selectedAspect = aspect;
  loadSelectedImage(scene, index, userData);
  // transform data so click coordinates will be correct
  selectTransform({ scene, userData });
}

// refactors
export function pointerClick(scene, userData) {
  let qt = quadtree();
  let ray = scene.ray;
  let coords;
  if (scene.selectedEnd[3] === -1) {
    coords = scene.coords;
  } else {
    coords = scene.selectedTransformed;
  }
  qt.addAll(coords);
  let closestCoord = qt.find(ray[0], ray[1]);
  let diff = [closestCoord[0] - ray[0], closestCoord[1] - ray[1]];
  let dist = Math.sqrt(diff[0] * diff[0] + diff[1] * diff[1]);
  let selectDist = Math.sqrt(
    scene.drawSize * scene.drawSize + scene.drawSize * scene.drawSize
  );
  let index = -1;
  for (let i = 0; i < coords.length; i++) {
    let coord = coords[i];
    if (
      coord[0].toFixed(8) === closestCoord[0].toFixed(8) &&
      coord[1].toFixed(8) === closestCoord[1].toFixed(8)
    ) {
      index = i;
      break;
    }
  }
  let checkDist = selectDist;
  if (scene.selectedEnd[3] !== -1) {
    let sizeAdjustment = scene.selectedSizeAdjustments[index];
    checkDist = Math.sqrt(
      sizeAdjustment * sizeAdjustment + sizeAdjustment * sizeAdjustment
    );
  }
  if (dist < checkDist) {
    if (scene.selectedEnd[3] === index) {
      // unselect selected
      scene.selectedStart = scene.selectedEnd.slice();
      scene.selectedEnd = [0, 0, 0, -1];
    } else {
      selectImage(scene, index, userData);
    }
  } else {
    scene.selectedStart = scene.selectedEnd.slice();
    scene.selectedEnd = [0, 0, 0, -1];
  }
  scene.selectTransition = 0;
}
