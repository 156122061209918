import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { centerXYPoints, collisionSpread } from './Fullsize/Utils';

const LOCAL_API_URL = 'http://localhost:8080';
const DEV_API_URL = 'https://dev.songsofothertimes.com';
const STAGING_API_URL = 'https://staging.songsofothertimes.com';
const PRODUCTION_API_URL = 'https://api.songsofothertimes.com';

// TODO(ganesh): These should be retrieved from the server instead of stored
// locally.
const DEV_S3_BUCKET_NAME = 'dev-soot-user-data';
const STAGING_S3_BUCKET_NAME = 'staging-soot-user-data';
const PRODUCTION_S3_BUCKET_NAME = 'prod-soot-user-data';

let ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'staging';

let CURRENT_S3_BUCKET_NAME = STAGING_S3_BUCKET_NAME;
let CURRENT_URL = STAGING_API_URL;
if (ENV === 'dev') {
  CURRENT_URL = DEV_API_URL;
  CURRENT_S3_BUCKET_NAME = DEV_S3_BUCKET_NAME;
} else if (ENV === 'production') {
  CURRENT_URL = PRODUCTION_API_URL;
  CURRENT_S3_BUCKET_NAME = PRODUCTION_S3_BUCKET_NAME;
} else if (ENV === 'local') {
  CURRENT_URL = LOCAL_API_URL;
  CURRENT_S3_BUCKET_NAME = STAGING_S3_BUCKET_NAME;
}

// Hardcode production
CURRENT_URL = PRODUCTION_API_URL;
CURRENT_S3_BUCKET_NAME = PRODUCTION_S3_BUCKET_NAME;

// console.log("Current environment: ", process.env.REACT_APP_ENV);
console.log('Current environment: ', 'production');

const instance = axios.create({
  baseURL: CURRENT_URL,
});

export const uploadNewZipfile = async (userKey, data) => {
  try {
    // A file isn't uploaded unless you get confirmation from a commit
    // or from s3 directly. So if the user requests an upload, we stage it.
    const stagedData = await instance
      .post('/stage_tmp', {
        contentType: 'application/zip',
        userKey,
      })
      .then((response) => response.data);
    const zipKey = stagedData.key;

    // The staged data needs to be thrown into a FormData object
    // before being POSTed to S3.
    const formData = new FormData();
    for (let field in stagedData.fields) {
      formData.append(field, stagedData.fields[field]);
    }

    // Also, the "key" field needs to come first in the object, or
    // else S3 throws a 400. Thus, we append the actual file last.
    formData.append('file', data);

    try {
      // POST the FormData to the presigned S3 URL
      await axios.post(stagedData.url, formData);

      // return the zipfile key so it can be sent to the commit endpoint
      return zipKey;
    } catch (err) {
      console.log(err);
    }
  } catch (err) {
    console.log(err);
  }
};

// Not using a catch here so that the client of this function
// can handle errors the best way for the user in each use case
export const commitZipfiles = async (zipKeys, userKey, branchName, branchKey) =>
  instance.post('/commit_zip', {
    zipKeys,
    userKey,
    branchName,
    ...(!!branchKey && { branchKey }),
  });

// Not using a catch here so that the client of this function
// can handle errors the best way for the user in each use case
export const getBranch = async (branchKey) =>
  // instance
  //   .post('/get_branch', {
  //     branchKey,
  //   })
  //   .then((response) => response.data);
  fetch(process.env.PUBLIC_URL + '/cache/get_branch.json').then((r) =>
    r.json(),
  );

// Not using a catch here so that the client of this function
// can handle errors the best way for the user in each use case
export const getDataFromArbitraryQuery = async (query) =>
  instance
    .post('/get_data_from_arbitrary_query', {
      query,
    })
    .then((response) => response.data);

export const transformBranch = (branch) => {
  // center points from umap
  // const centeredUmap = centerXYPoints(branch.metadata.umap2dCoords);

  let transformed = {
    data: {
      // add 0 z coord to 2D coords for WebGL
      umap2dCoords: branch.metadata.umap2dCoords,
      hdbscanClusters: branch.metadata.hdbscanClusters,
      hdbscanClusterProbabilities: branch.metadata.hdbscanClusterProbabilities,
    },
    originalImages: branch.metadata.key,
  };
  // format atlases
  transformed.atlases = {};
  let sizes = [256];
  for (let size of sizes) {
    transformed.atlases[size] = {
      coords: branch.atlases[size].coordsKeys,
      keys: branch.atlases[size].basisKeys,
    };
  }

  return transformed;
};

export const prependS3UrlToKey = (key) =>
  `https://s3.amazonaws.com/${CURRENT_S3_BUCKET_NAME}/${key}`;

// Not using a catch here so that the client of this function
// can handle errors the best way for the user in each use case
export const getUserBranches = async (userKey, requireReadyStatus = false) =>
  instance
    .post('/get_user_branches', { userKey, requireReadyStatus })
    .then((response) => response.data);

// Not using a catch here so that the client of this function
// can handle errors the best way for the user in each use case
export const getPublicBranches = async () =>
  instance.get('/get_public_branches').then((response) => response.data);

// Expects a 204 No Content response, so not using a then clause here
export const updateBranchPermissions = async (branchKey, newPermissions) =>
  instance.post('/update_branch_permissions', { branchKey, newPermissions });

// Helper function to toggle the public.edit property
// of the branchInfo.permissions object, so that the UI is a bit neater
export const toggleBranchPublicEdit = async (branch) =>
  // send back an updated version of the permissions object
  updateBranchPermissions(branch.key, {
    ...branch.branchInfo.permissions,
    public: {
      edit: !branch.branchInfo.permissions.public.edit,
    },
  });

// Expects a 204 No Content response, so not using a then clause here
export const updateBranchName = async (branchKey, newBranchName) =>
  instance.post('/update_branch_name', { branchKey, newBranchName });

// Expects a 204 No Content response, so not using a then clause here
export const deleteBranch = async (branchKey) =>
  instance.post('/delete_branch', { branchKey });

// Takes imageKeys as required input, an array of
// the S3 keys of the images to be downloaded
export const downloadImages = async (imageKeys, outputBasename = 'soot') => {
  const zip = new JSZip();

  for (const [index, key] of imageKeys.entries()) {
    const imageSrc = prependS3UrlToKey(key);
    const response = await fetch(imageSrc);
    const blob = response.blob();

    zip.file(`image${index}`, blob);
  }

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, outputBasename);
};

// Not using a catch here so that the client of this function
// can handle errors the best way for the user in each use case
export const downloadAllImagesInBranch = async (branchKey, branchName) =>
  getBranch(branchKey)
    .then((branch) => branch.metadata.key)
    .then((imageKeys) => downloadImages(imageKeys, branchName));

// Not using a catch here so that the client of this function
export const registerNewGame = async (ownerKey, branchKey) =>
  instance
    .post('/register_new_game', {
      ownerKey,
      branchKey,
    })
    .then((response) => response.data);

// Not using a catch here so that the client of this function
export const updateGameScore = async (gameKey, userKey, score) =>
  instance
    .post('/update_game_score', {
      gameKey,
      userKey,
      score,
    })
    .then((response) => response.data);

export const getAran = async (userKey, branchKey) =>
  // instance
  //   .post("/get_aran", { userKey, branchKey })
  //   .then((response) => response.data);
  fetch(process.env.PUBLIC_URL + '/cache/get_aran.json').then((r) => r.json());
export const getGrid = async (userKey, branchKey) =>
  // instance
  //   .post("/get_grid", { userKey, branchKey })
  //   .then((response) => response.data);
  fetch(process.env.PUBLIC_URL + '/cache/get_grid.json').then((r) => r.json());
export const getUmap2d = async (userKey, branchKey) =>
  instance
    .post('/get_umap_2d', { userKey, branchKey })
    .then((response) => response.data);
