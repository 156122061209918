// Container for the webGL visualization. Reads in a branchKey
// from the URL params and feeds this into the Visualization component,
// which then renders the correct visualization

import { Button, TextareaAutosize } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";

import {
  registerNewGame,
  updateGameScore,
  getBranch,
  getDataFromArbitraryQuery,
  transformBranch,
  getGrid,
  getUmap2d,
  getAran,
} from "./client";
import Visualization from "./Fullsize/Visualize";
import VisualizationContext from "./VisualizationContext";
import { centerXYPoints, collisionSpread } from "./Fullsize/Utils";

function VisualizationContainer({
  lowResTextures,
  isFindTheImageGame,
  isQuery,
  branchKey,
}) {
  const [branchName, setBranchName] = useState("");
  const currentUserKey = "test";
  const [transformedBranch, setTransformedBranch] = useState({});
  const [islandCoords, setIslandCoords] = useState({});
  const [query, setQuery] = useState("");
  const [isQuerySubmitted, setIsQuerySubmitted] = useState(false);
  const [transitionLoad, setTransitionLoad] = useState(false);

  const [imageKeyForGame, setImageKeyForGame] = useState();
  const [imageIndexForGame, setImageIndexForGame] = useState();
  const [gameKey, setGameKey] = useState();
  const [timeElapsed, setTimeElapsed] = useState(null);
  const [endGame, setEndGame] = useState();
  const [cancelGame, setCancelGame] = useState();
  const [isGameOver, setIsGameOver] = useState(false);
  const [isGameCancelled, setIsGameCancelled] = useState(false);

  const loadDataFromQuery = async () => {
    try {
      const branch = await getDataFromArbitraryQuery(query);
      setBranchName(branch.branchName);
      const transformed = transformBranch(branch);
      // log object for now
      console.log(transformed);
      setTransformedBranch(transformed);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const loadDataFromBranch = async () => {
      try {
        // If location.state is defined AND it has a "branch" property, then the branch
        // has already been pulled down in the directory to check how many images are
        // in it. So we can use that data and don't have to pull it down again. Otherwise,
        // the user is not coming to this  route from the Directory at all so we need to
        // call getBranch.
        // const branch = (location.state && location.state.branch) || (await getBranch(branchKey));
        const branch = await getBranch(branchKey);
        // hack to load island first
        const island = await getAran(currentUserKey, branchKey);
        let coords3d = island.coords.map((c) => {
          if (c.length < 3) {
            return [...c, 0];
          } else {
            return c;
          }
        });
        // coords3d = centerXYPoints(coords3d);
        // let rescale = 0.004;
        // coords3d = coords3d.map((p) => {
        //   return [p[0] * rescale, p[1] * rescale, p[2]];
        // });
        // // flip y
        // // coords3d = coords3d.map((p) => {
        // //   return [p[0], p[1] * -1, p[2]];
        // // });
        // coords3d = collisionSpread(coords3d);
        // let test = { coords: coords3d, keys: island.keys };
        // console.log(JSON.stringify(test));
        setIslandCoords({ coords: coords3d, keys: island.keys });
        branch.metadata.umap2dCoords = coords3d;
        branch.key = island.keys;
        setBranchName(branch.branchName);
        const transformed = transformBranch(branch);
        // log object for now
        console.log(transformed);
        setTransformedBranch(transformed);
      } catch (err) {
        console.log(err);
      }
    };

    const _registerNewGame = async () => {
      const {
        gameKey: newGameKey,
        imageKey: newImageKey,
      } = await registerNewGame(currentUserKey, branchKey);
      setGameKey(newGameKey);
      setImageKeyForGame(newImageKey);
    };

    loadDataFromBranch();
    if (isFindTheImageGame) {
      _registerNewGame();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(transformedBranch).length > 0 && imageKeyForGame) {
      setImageIndexForGame(
        transformedBranch.originalImages.indexOf(imageKeyForGame)
      );
    }
  }, [transformedBranch, imageKeyForGame]);

  if (isQuery && !isQuerySubmitted) {
    return (
      <div>
        <div>
          <TextareaAutosize
            rowsMin={10}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
        <div>
          <Button
            onClick={() => {
              setIsQuerySubmitted(true);
              loadDataFromQuery();
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <video
          playsInline
          autoPlay
          muted
          loop
          style={{
            objectFit: "cover",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            pointerEvents: "none",
          }}
        >
          <source
            src={process.env.PUBLIC_URL + "/ocean.mp4"}
            type="video/mp4"
          />
        </video>
        {Object.keys(transformedBranch).length === 0 ? (
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div>
              <img
                style={{
                  maxWidth: "calc(100% - 16px)",
                  display: "block",
                  margin: "0 auto 12px auto",
                }}
                src={process.env.PUBLIC_URL + "/images/island.jpg"}
              />
              <div>Loading the collection...</div>
            </div>
          </div>
        ) : (
          <VisualizationContext.Provider
            value={{
              startGame: () => {
                setTimeElapsed(Date.now() - Date.now());
                const startTime = Date.now();
                const stopwatch = setInterval(() => {
                  setTimeElapsed(Date.now() - startTime);
                }, 1000);
                setEndGame(() => () => {
                  clearInterval(stopwatch);
                  try {
                    updateGameScore(gameKey, currentUserKey, timeElapsed);
                    setIsGameOver(true);
                  } catch (error) {
                    console.log(error);
                  }
                });
                setCancelGame(() => () => {
                  clearInterval(stopwatch);
                  setIsGameCancelled(true);
                });
              },
              timeElapsed,
              imageKeyForGame,
              imageIndexForGame,
              endGame,
              isGameOver,
              cancelGame,
              isGameCancelled,
              isFindTheImageGame,
              getGrid: () => getGrid(currentUserKey, branchKey),
              getUmap2d: () => getUmap2d(currentUserKey, branchKey),
              getAran: () => getAran(currentUserKey, branchKey),
              userData: transformedBranch,
              islandCoords: islandCoords,
              setTransitionLoad,
            }}
          >
            <div
              style={{
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                userSelect: "none",
              }}
            >
              <div
                className={
                  transitionLoad ? "islandImage loaded" : "islandImage"
                }
              >
                <img
                  style={{
                    maxWidth: "calc(100% - 16px)",
                    display: "block",
                    margin: "0 auto 12px auto",
                  }}
                  src={process.env.PUBLIC_URL + "/images/island.jpg"}
                />
                <div>Loading the collection...</div>
              </div>
            </div>

            <Visualization
              userKey={currentUserKey}
              branchName={branchName}
              lowResTextures={lowResTextures}
            />
          </VisualizationContext.Provider>
        )}
      </>
    );
  }
}

export default VisualizationContainer;
