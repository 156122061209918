// A Profile is mapped to one visualization/zipfile. This component
// has a label and image, linked to the user profile.

import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Delete as DeleteIcon,
  CloudDownload as DownloadIcon,
  CloudUpload as UploadIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  Public as PublicIcon,
  SportsEsports as SportsEsportsIcon,
} from '@material-ui/icons';

import defaultImage from './images/icon.png';
import WarningTooltip from './WarningTooltip';
import StyledIconButton from './StyledIconButton';

const BRANCH_WARNING_MINIMUM_LENGTH = 4000;

function Profile({
  branch,
  isOwnedByCurrentUser,
  handleDownload,
  handleDeleteDialogOpen,
  handleEditNameDialogOpen,
  handlePropertiesDialogOpen,
}) {
  const history = useHistory();
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const hasWarning = branch.branchInfo.imageCount > BRANCH_WARNING_MINIMUM_LENGTH;

  return (
    <div onMouseOver={() => setMouseIsOver(true)} onMouseOut={() => setMouseIsOver(false)}>
      <div
        className="profile-image-container"
        style={{
          textAlign: 'center',
          display: 'block',
          color: 'inherit',
          textDecoration: 'none',
        }}
        onClick={() => {
          history.push(`/${branch.key}`);
        }}
      >
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <h2
              style={{
                color: 'white',
              }}
            >
              {branch.branchInfo.branchName}
            </h2>
          </Grid>
          {branch.branchInfo.permissions.public.edit && (
            <Grid item style={{ paddingTop: '28px' }}>
              <PublicIcon />
            </Grid>
          )}
        </Grid>
        <img
          src={defaultImage}
          style={{
            maxHeight: '100px',
          }}
        />
        <Grid container justify="center">
          <Grid item style={{ paddingLeft: hasWarning ? '50px' : '0px' }}>
            <h4>{branch.branchInfo.imageCount && `${branch.branchInfo.imageCount} images`}</h4>
          </Grid>
          <Grid item style={{ paddingTop: '9px' }}>
            {hasWarning && <WarningTooltip imageCount={branch.branchInfo.imageCount} />}
          </Grid>
        </Grid>
      </div>
      <Grid container justify="center" spacing={3}>
        {isOwnedByCurrentUser && (
          <>
            <Grid item>
              <StyledIconButton
                isDisplayed={mouseIsOver}
                IconComponent={InfoIcon}
                handleClick={() => handlePropertiesDialogOpen(branch)}
              />
            </Grid>
            <Grid item>
              <StyledIconButton
                isDisplayed={mouseIsOver}
                IconComponent={EditIcon}
                handleClick={() => handleEditNameDialogOpen(branch)}
              />
            </Grid>
            <Grid item>
              <StyledIconButton
                isDisplayed={mouseIsOver}
                IconComponent={DownloadIcon}
                handleClick={() => handleDownload(branch)}
              />
            </Grid>
            <Grid item>
              <StyledIconButton
                isDisplayed={mouseIsOver}
                IconComponent={UploadIcon}
                handleClick={() =>
                  window.open(
                    `${window.location.origin}${window.location.pathname}#upload/${branch.key}`,
                    '_blank',
                  )
                }
              />
            </Grid>
            <Grid item>
              <StyledIconButton
                isDisplayed={mouseIsOver}
                IconComponent={DeleteIcon}
                handleClick={() => handleDeleteDialogOpen(branch)}
              />
            </Grid>
          </>
        )}

        <Grid item>
          <StyledIconButton
            isDisplayed={mouseIsOver}
            IconComponent={SportsEsportsIcon}
            handleClick={() =>
              window.open(
                `${window.location.origin}${window.location.pathname}#findTheImageGame/${branch.key}`,
                '_blank',
              )
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
