import React, { useState, useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  aAtlasesLoaded,
  aGridCoords,
  aLayout,
  aProfile,
  aScroller,
  aShowControls,
  aUserData,
  aSize,
} from "./State.js";
import VisualizationContext from "../VisualizationContext.jsx";
import { updateCoords } from "./Actions";
import { centerXYPoints, collisionSpread, getSizeKey } from "./Utils";
import { initDrawSize } from "./Constants";
import { selectImage } from "./PointerUtils.js";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function Controls({ scene_ref, branchName }) {
  let scene = scene_ref.current;
  let atlasesLoaded = useRecoilValue(aAtlasesLoaded);
  let [layout, setLayout] = useRecoilState(aLayout);
  let [scroller, setScroller] = useRecoilState(aScroller);
  let [showControls, setShowControls] = useRecoilState(aShowControls);
  let [loadingLayout, setLoadingLayout] = useState(false);
  let [userData, setUserData] = useRecoilState(aUserData);
  let [gridCoords, setGridCoords] = useRecoilState(aGridCoords);
  let [islandCoords, setIslandCoords] = useRecoilState(aGridCoords);
  let [showAbout, setShowAbout] = useState(false);
  const context = useContext(VisualizationContext);
  const [buttonSize, setButtonSize] = useState(100);
  const size = useRecoilValue(aSize);

  async function handleAranCollisionClick() {
    setLayout("island");
    setLoadingLayout(true);
    let response;
    // assume island coords are cached from initial request
    response = context.islandCoords;
    let { coords, keys } = response;
    updateCoords({
      scene_ref,
      coords,
      keys,
      userData,
      setUserData,
      coordType: "aranCollision",
    });
    setLoadingLayout(false);
    if (scene.selectedEnd[3] !== -1) {
      selectImage(scene, scene.selectedEnd[3], userData);
    }
    setLoadingLayout(false);
  }
  // async function handlePetalClick() {
  //   setLoadingPetal(true);
  //   let { coords, keys } = await context.getUmap2d();
  //   updateCoords({
  //     scene_ref,
  //     coords,
  //     keys,
  //     userData,
  //     setUserData,
  //     coordType: "umap",
  //   });
  //   setLoadingPetal(false);
  // }
  async function handleGridClick() {
    setLayout("grid");
    setLoadingLayout(true);
    let response;
    if (gridCoords === null) {
      response = await context.getGrid();
      let coords3d = centerXYPoints(response.coords);
      // rescale grid
      let nearestX = Infinity;
      let checkPoint = coords3d[0];
      let drawSize = initDrawSize;
      for (let point of coords3d.slice(1)) {
        // only look in same row
        if (point[1] === checkPoint[1]) {
          let xDist = Math.abs(point[0] - checkPoint[0]);
          if (xDist < nearestX) nearestX = xDist;
        }
      }
      // add padding
      let rescale = (drawSize - 0.01) / nearestX;
      coords3d = coords3d.map((p) => {
        return [p[0] * rescale, p[1] * rescale, p[2]];
      });
      // calc used for setting radius maybe
      // let max = 0;
      // for (let coord of coords3d) {
      //   if (coord[1] > max) max = coord[1];
      // }
      // console.log(max);

      setGridCoords({ coords: coords3d, keys: response.keys });
      response = { coords: coords3d, keys: response.keys };
    } else {
      response = gridCoords;
    }
    let { coords, keys } = response;
    updateCoords({
      scene_ref,
      coords,
      keys,
      userData,
      setUserData,
      coordType: "grid",
    });
    if (scene.selectedEnd[3] !== -1) {
      selectImage(scene, scene.selectedEnd[3], userData);
    }
    setLoadingLayout(false);
  }

  function handleEnter(e) {
    scene.distortionActive = 0;
  }

  function handleLeave(e) {
    scene.distortionActive = 1;
  }

  useEffect(() => {
    if (atlasesLoaded) {
      setTimeout(() => {
        setShowAbout(true);
      }, 566);
    }
  }, [atlasesLoaded]);

  return (
    <React.Fragment>
      <div
        className={showAbout ? "aboutLink aboutLinkLoaded" : "aboutLink"}
        style={{
          position: "fixed",
          right: 48,
          bottom: 48,
          userSelect: "none",
        }}
      >
        <a
          style={{
            color: "white",
            textDecoration: "none",
          }}
          href="https://www.dropeverything.net/ldb"
          target="_blank"
        >
          About
        </a>
      </div>
      <div
        style={{
          position: "fixed",
          left: size[0] < 640 ? 24 : 48,
          bottom: size[0] < 640 ? 24 : 48,
          height: buttonSize,
          width: buttonSize,
          userSelect: "none",
        }}
        className={
          loadingLayout
            ? "layoutControls loading"
            : layout === "island"
            ? "layoutControls showGrid"
            : "layoutControls showIsland"
        }
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      >
        <div
          className={atlasesLoaded ? "initialGrow load" : "initialGrow"}
          style={{
            height: buttonSize,
          }}
        >
          <div
            onClick={handleGridClick}
            id="gridButton"
            className="layoutButton"
            role="button"
            style={{
              height: buttonSize,
              width: buttonSize,
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/gridButton.png)`,
              backgroundSize: "75% 75%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            onClick={handleAranCollisionClick}
            id="islandButton"
            className="layoutButton"
            role="button"
            style={{
              height: buttonSize,
              width: buttonSize,
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/islandButton.png)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </div>
      {layout === 2 ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 16,
            padding: 16,
            pointerEvents: "none",
            display: "none",
          }}
        >
          Image select not available in Cloud mode
        </div>
      ) : null}

      <div
        style={{
          position: "fixed",
          left: 0,
          bottom: 16,
          width: "100%",
          padding: 16,
          paddingRight: 24,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pointerEvents: "none",
          // pointerEvents: 'auto',
          userSelect: "none",
          display: "none",
        }}
      >
        <div
          style={{
            alignSelf: "flex-end",
          }}
        ></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flexGrow: 1 }}></div>
          <div
            style={{
              display: showControls ? "flex" : "none",
              padding: "8px 12px 8px",
              background: "rgba(50,50,50,0.9)",
            }}
          >
            <div
              style={{
                borderRight: "solid 2px rgba(0,0,0,0.2)",
                marginRight: 16,
                paddingRight: 12,
              }}
            >
              <div className="label">Views:</div>
              <div style={{ display: "flex" }}>
                {/* NOTE: Changing these names will change the event_label in Google Analytics */}
                {[
                  ["Petal", "1"],
                  ["Grid", "2"],
                  ["Cloud", "3"],
                ].map(([name, keyboard], i) => {
                  return (
                    <div
                      key={name + "_button"}
                      id={name + "_button"}
                      role="button"
                      className={
                        layout === i ? "controlButton active" : "controlButton"
                      }
                      style={{
                        padding: "6px 16px 8px 16px",
                        textAlign: "center",
                        pointerEvents: "auto",
                      }}
                      onClick={() => {
                        setLayout(i);
                      }}
                    >
                      <div>{name}</div>
                      <div>
                        <span
                          style={{
                            background: "rgba(100,100,100,0.5)",
                            width: 18,
                            textAlign: "center",
                            fontSize: 13,
                            color: "white",
                            display: "inline-block",
                            lineHeight: 1.5,
                          }}
                        >
                          {keyboard}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                borderRight: "solid 2px rgba(0,0,0,0.2)",
                marginRight: 16,
                paddingRight: 12,
              }}
            >
              <div className="label">Scrollers:</div>
              <div style={{ display: "flex" }}>
                {[
                  ["Blob", "9"],
                  ["Pick", "0"],
                ].map(([name, keyboard], i) => {
                  return (
                    <div
                      key={name + "_button"}
                      role="button"
                      className={
                        name.toLowerCase() === scroller
                          ? "controlButton active"
                          : "controlButton"
                      }
                      style={{
                        padding: "6px 16px 8px 16px",
                        textAlign: "center",
                        pointerEvents: "auto",
                      }}
                      onClick={() => {
                        setScroller(name.toLowerCase());
                      }}
                    >
                      <div>{name}</div>
                      <div>
                        <span
                          style={{
                            background: "rgba(100,100,100,0.5)",
                            width: 18,
                            textAlign: "center",
                            fontSize: 13,
                            color: "white",
                            display: "inline-block",
                            lineHeight: 1.5,
                          }}
                        >
                          {keyboard}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: 6,
              }}
            >
              <div
                className="label"
                style={{
                  marginBottom: 8,
                }}
              >
                Image size:
              </div>
              <div>
                <span
                  style={{
                    background: "rgba(100,100,100,0.5)",
                    minWidth: 18,
                    padding: "0 6px",
                    textAlign: "center",
                    fontSize: 13,
                    color: "white",
                    display: "inline-block",
                    lineHeight: 1.5,
                  }}
                >
                  Shift
                </span>{" "}
                + Scroll
              </div>
            </div>
          </div>
          <div
            style={{
              display: showControls ? "none" : "block",
              padding: "0",
              background: "rgba(50,50,50,0.9)",
            }}
          >
            <div
              role="button"
              className={"controlButton"}
              style={{
                padding: "6px 16px 8px 16px",
                textAlign: "center",
                pointerEvents: "auto",
              }}
              onClick={() => {
                setShowControls(true);
              }}
            >
              <div>Show tools</div>
              <div>
                <span
                  style={{
                    background: "rgba(100,100,100,0.5)",
                    minWidth: 18,
                    padding: "0 6px",
                    textAlign: "center",
                    fontSize: 13,
                    color: "white",
                    display: "inline-block",
                    lineHeight: 1.5,
                  }}
                >
                  spacebar
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Controls;
