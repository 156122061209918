import { atom, atomFamily, selector } from "recoil";
import { getWorldFromPx, castRay, zoomLimit } from "./World";
import { zoomStart, initDrawSize } from "./Constants";
import { updateHover } from "./PointerUtils";

export let aSize = atom({
  key: "size",
  default: [null, null],
});

export let aAuthenticated = atom({
  key: "authenticated",
  default: false,
});

export let aCamera = atom({
  key: "camera",
  default: [0, 0, zoomStart],
});

export let aLayout = atom({
  key: "layout",
  default: "island",
});

export let aUploading = atom({
  key: "uploading",
  default: null,
});

export let aEmbedding = atom({
  key: "embedding",
  default: null,
});

export let aUploadName = atom({
  key: "uploaded",
  default: null,
});

export let sPanCamera = selector({
  key: "panCamera",
  set: ({ get, set }, { scene, diff }) => {
    let [w, h] = get(aSize);
    let sizeAspect = w / h;
    let panLimitVal = 6;
    function panLimitX(new_zoom) {
      let max = panLimitVal * sizeAspect;
      let min = -panLimitVal * sizeAspect;
      return Math.min(max, Math.max(min, new_zoom));
    }
    function panLimitY(new_zoom) {
      let max = panLimitVal / sizeAspect;
      let min = -panLimitVal / sizeAspect;
      return Math.min(max, Math.max(min, new_zoom));
    }

    // let userData = get(aUserData);
    let [dx, dy] = diff;
    let new_camera = get(aCamera).slice();
    let ndx = getWorldFromPx(scene, dx, new_camera[2]);
    let ndy = getWorldFromPx(scene, dy, new_camera[2]);
    new_camera[0] -= ndx;
    new_camera[1] += ndy;
    new_camera[0] = panLimitX(new_camera[0]);
    new_camera[1] = panLimitY(new_camera[1]);
    set(aCamera, new_camera);
    // if (updateHoverCallback === true) {
    //   setTimeout(() => {
    //     updateHover(scene, userData);
    //   }, 0);
    // }
  },
});

export let aUploads = atom({
  key: "uploads",
  default: [],
});

export let afUpload = atomFamily({
  key: "upload",
  default: null,
});

export let aClicked = atom({
  key: "clicked",
  default: null,
});

// temp, just set upload
export let sSetUpload = selector({
  key: "setUpload",
  set: ({ get, set }, { id, data }) => {
    let data_object = { data: data };
    set(afUpload(id), data_object);
    set(aUploads, [id]);
  },
});

export let sAddUpload = selector({
  key: "addUpload",
  set: ({ get, set }, { id, data }) => {
    let _uploads = get(aUploads).slice();
    _uploads.push(id);
    let data_object = { data: data };
    set(afUpload(id), data_object);
    set(aUploads, _uploads);
  },
});

export let sExpandedUploads = selector({
  key: "expandedUploads",
  get: ({ get }) => {
    let ids = get(aUploads);
    let expandedUploads = ids.map((id) => {
      return get(afUpload(id));
    });
    return expandedUploads;
  },
});

export let aDrawSize = atom({
  key: "drawSize",
  default: initDrawSize,
});

export let sDrawSize = selector({
  key: "setDrawSize",
  set: ({ get, set }, { position, scene, sign, mult }) => {
    let drawSize = get(aDrawSize);
    let new_size;
    if (sign < 0) {
      new_size = mult * drawSize;
    } else {
      new_size = drawSize / mult;
    }
    set(aDrawSize, new_size);
  },
});

export let aProfile = atom({
  key: "profile",
  default: null,
});

export let aUserData = atom({
  key: "userData",
  default: null,
});

// blob, pick
export let aScroller = atom({
  key: "scroller",
  default: "pick",
});

export let aShowControls = atom({
  key: "showControls",
  default: false,
});

export let sToggleShowControls = selector({
  key: "toggleShowControls",
  set: ({ get, set }) => {
    let current = get(aShowControls);
    set(aShowControls, !current);
  },
});

export let aAtlasesLoaded = atom({
  key: "atlasesLoaded",
  default: false,
});

export let aIslandCoords = atom({
  key: "islandCoords",
  default: null,
});

export let aGridCoords = atom({
  key: "gridCoords",
  default: null,
});
