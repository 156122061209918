import { multiplyPoint } from "./Mat4.js";

export function getProjectionZ(scene, worldZ) {
  let projected = multiplyPoint(scene.projection, [0, 0, worldZ, 1]);
  let ndz = projected[2] / projected[3];
  return ndz;
}

export function getWorldFromPx(scene, px, camera_dist) {
  let z = getProjectionZ(scene, -camera_dist);
  let raw = [(2 * px) / scene.width, 0, z, 1];
  let point_world = multiplyPoint(scene.inverseProjection, raw);
  return point_world[0] / point_world[3];
}

export function getNormalizedZ(scene, worldZ) {
  let projected = multiplyPoint(scene.viewProjection, [0, 0, worldZ, 1]);
  let ndz = projected[2] / projected[3];
  return ndz;
}

export function getClipsFromMouse(viewport, mouse_position) {
  let ratio_x = mouse_position[0] / viewport[0];
  let ratio_y = (viewport[1] - mouse_position[1]) / viewport[1];
  let clip_x = 2 * ratio_x - 1;
  let clip_y = 2 * ratio_y - 1;
  return [clip_x, clip_y];
}

export function castRay(scene, pointer, worldZ) {
  let ndz = getNormalizedZ(scene, worldZ);
  let clips = getClipsFromMouse([scene.width, scene.height], pointer);
  let device_coordinates = [clips[0], clips[1], ndz, 1];
  let point_world = multiplyPoint(
    scene.inverseViewProjection,
    device_coordinates
  );
  // divide by w at end
  let world_points = [
    point_world[0] / point_world[3],
    point_world[1] / point_world[3],
  ];
  return world_points;
}

export function zoomLimit(new_zoom) {
  let max = 40;
  let min = 0.08;
  return Math.min(max, Math.max(min, new_zoom));
}
