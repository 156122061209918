// Top-level application component for entire frontend

import { HashRouter as Router } from "react-router-dom";

import "firebase/auth";

import AuthProvider from "./firebase";
import SwitchContainer from "./SwitchContainer";

import "./App.css";
import VisualizationContainer from "./VisualizationContainer";

const App = () => (
  <div className="App">
    <VisualizationContainer branchKey="47aa1926-286c-4fb9-947f-540df5b7f6f1"></VisualizationContainer>
  </div>
);

export default App;
