import { prependS3UrlToKey } from "../client";
import { quadtree } from "d3-quadtree";
import { getWorldFromPx, castRay, zoomLimit } from "./World";

let upload_id = 0;
export function makeUploadID() {
  upload_id++;
  return upload_id;
}

export function shareLink(url) {
  let prefix;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    prefix = "/sharedpublic";
  } else {
    prefix = "/AtlasExperiments/sharedpublic";
  }
  return prefix + url;
}

export function publicLink(url) {
  return process.env.PUBLIC_URL + url;
}

export function getSizeKey(size) {
  return size + "_atlas";
}

export function loadSelectedImage(scene, index, userData) {
  scene.selectedFullsizeLoaded = false;
  let filename = userData.originalImages[index] || "";
  if (filename === scene.selectedTextureCheck) {
    // already loaded
    scene.selectedFullsizeLoaded = true;
  } else {
    scene.selectedTextureCheck = filename;
    // TODO fix this
    scene.selectedTexture(scene.placeholderCanvas);
    let image = new Image();
    image.crossOrigin = "";
    image.onload = function () {
      if (filename === scene.selectedTextureCheck) {
        scene.selectedTexture(image);
        scene.selectedFullsizeLoaded = true;
      }
      image = null;
    };
    image.src = prependS3UrlToKey(filename);
  }
}

export const centerXYPoints = (points) => {
  const totals = points.reduce(
    (total, cur) => {
      total[0] += cur[0];
      total[1] += cur[1];
      return total;
    },
    [0, 0]
  );
  const average = totals.map((v) => v / points.length);
  const centered = points.map((a) => [a[0] - average[0], a[1] - average[1], 0]);
  return centered;
};

export const collisionSpread = (points) => {
  // use collision detection to s.map((v) => [...v, 0])pread umap coords
  let spreadCoords = JSON.parse(JSON.stringify(points));
  let inc = 0.1;
  let alpha = inc;
  for (let i = 0; i < 300; i++) {
    let tree = quadtree().addAll(spreadCoords);
    for (const point of spreadCoords) {
      var r = inc * 2,
        nx1 = point[0] - r,
        nx2 = point[0] + r,
        ny1 = point[1] - r,
        ny2 = point[1] + r;
      // visit each squares in the quadtree
      // x1 y1 x2 y2 constitues the coordinates of the square
      // we want to check if each square is a leaf node (has data prop)
      tree.visit((visited, x1, y1, x2, y2) => {
        if (visited.data && visited.data !== point) {
          let x = point[0] - visited.data[0],
            y = point[1] - visited.data[1],
            l = Math.sqrt(x * x + y * y),
            r = inc + inc;
          if (l < r) {
            // if quadtree leaf and input node collides
            l = ((l - r) / l) * alpha;
            point[0] -= x *= l;
            point[1] -= y *= l;
            visited.data[0] += x;
            visited.data[1] += y;
          }
        }
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    }
  }
  return spreadCoords;
};

export let zoomCamera = ({ position, scene, sign, mult }) => {
  let new_camera = scene.camera.slice();
  let zoom = new_camera[2];
  let new_poss;
  if (sign > 0) {
    new_poss = zoom * mult;
  } else {
    new_poss = zoom / mult;
  }
  let new_zoom = zoomLimit(new_poss);
  if (new_zoom > zoom || new_zoom < zoom) {
    let projected = castRay(scene, position, new_zoom);
    let new_camera = [...projected, new_zoom];
    scene.camera = new_camera;
  }
};

export let panCamera = ({ scene, diff }) => {
  let sizeAspect = scene.width / scene.height;
  let panLimitVal = 6;
  function panLimitX(new_zoom) {
    let max = panLimitVal * sizeAspect;
    let min = -panLimitVal * sizeAspect;
    return Math.min(max, Math.max(min, new_zoom));
  }
  function panLimitY(new_zoom) {
    let max = panLimitVal / sizeAspect;
    let min = -panLimitVal / sizeAspect;
    return Math.min(max, Math.max(min, new_zoom));
  }

  let [dx, dy] = diff;
  let new_camera = scene.camera.slice();
  let ndx = getWorldFromPx(scene, dx, new_camera[2]);
  let ndy = getWorldFromPx(scene, dy, new_camera[2]);
  new_camera[0] -= ndx;
  new_camera[1] += ndy;
  // new_camera[0] = panLimitX(new_camera[0]);
  // new_camera[1] = panLimitY(new_camera[1]);
  scene.camera = new_camera;
};
