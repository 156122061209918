import { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Directory from "./Directory";
import VisualizationContainer from "./VisualizationContainer";

import Login from "./Login";
import PrivateRoute from "./PrivateRoute";
import Upload from "./Upload";
import Navbar from "./Navbar";

function SwitchContainer({ password }) {
  const location = useLocation();

  return (
    <>
      <Switch>
        <PrivateRoute path="/directory">
          <Directory />
          <Navbar />
        </PrivateRoute>
        <PrivateRoute path="/query">
          <VisualizationContainer isQuery={true} />
        </PrivateRoute>
        <PrivateRoute path="/upload/:branchKey?">
          <Upload />
          <Navbar />
        </PrivateRoute>
        <PrivateRoute path="/lowres/:branchKey">
          <VisualizationContainer lowResTextures={true} />
        </PrivateRoute>
        <PrivateRoute path="/findTheImageGame/:branchKey">
          <VisualizationContainer isFindTheImageGame={true} />
        </PrivateRoute>
        <PrivateRoute path="/:branchKey">
          <VisualizationContainer />
        </PrivateRoute>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </>
  );
}

export default SwitchContainer;
