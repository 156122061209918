import { useState, useEffect, useContext, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { aProfile, aCamera, sDrawSize, aClicked, aUserData } from "./State.js";
import { getSizeKey, zoomCamera, panCamera } from "./Utils";
import { updateHover, pointerClick } from "./PointerUtils";
import VisualizationContext from "../VisualizationContext.jsx";

function Pointer({ scene_ref, canvas_ref }) {
  let profile = useRecoilValue(aProfile);
  let userData = useRecoilValue(aUserData);
  let setCamera = useSetRecoilState(aCamera);
  let setDrawSize = useSetRecoilState(sDrawSize);
  let setClicked = useSetRecoilState(aClicked);
  let scene = scene_ref.current;
  let tapRef = useRef(false);
  const context = useContext(VisualizationContext);

  function panAndUpdate(position, panArgs) {
    panCamera(panArgs);
    scene.pointer.position = position;
  }

  function getPosition(e) {
    var x = e.clientX;
    var y = e.clientY;
    return [x, y];
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     canvas_ref.current.click();
  //     let fake = {
  //       id: 0,
  //       position: [0, 0],
  //     };
  //     scene.pointers.push(fake);

  //     panCamera({ scene, diff: [10, 10] });

  //     updateHover(scene);
  //   }, 2000);
  // }, []);

  useEffect(() => {
    let canvas = canvas_ref.current;
    let pointers = scene.pointers;

    function pointerMove(e) {
      let idx = pointers.map((o) => o.id).indexOf(e.pointerId);
      let position = getPosition(e);

      // tapRef.current = false;
      if (idx >= 0) {
        if (pointers.length < 2) {
          let dx = position[0] - pointers[0].position[0];
          let dy = position[1] - pointers[0].position[1];
          panCamera({ scene, diff: [dx, dy] });
          pointers[idx].position = position;
          updateHover(scene);
        } else {
          {
            let prevDiff = [
              pointers[1].position[0] - pointers[0].position[0],
              pointers[1].position[1] - pointers[0].position[1],
            ];
            pointers[idx].position = position;
            if (idx === 1) {
              let diff = [
                pointers[1].position[0] - pointers[0].position[0],
                pointers[1].position[1] - pointers[0].position[1],
              ];
              let prevDist = Math.sqrt(
                prevDiff[0] * prevDiff[0] + prevDiff[1] * prevDiff[1]
              );
              let dist = Math.sqrt(diff[0] * diff[0] + diff[1] * diff[1]);
              let distDiff = dist - prevDist || 0;
              if (distDiff !== 0) {
                zoomCamera({
                  position: [window.innerWidth / 2, window.innerHeight / 2],
                  scene,
                  sign: distDiff > 0 ? -1 : 1,
                  mult: 1 + Math.min(Math.abs(distDiff) / 100, 0.125),
                });
              }
            }
          }
        }
      } else {
        if (scene.pointerMiddleDown) {
          let dx = position[0] - scene.mousePointer[0];
          let dy = position[1] - scene.mousePointer[1];
          panCamera({ scene, diff: [dx, dy] });
          scene.mousePointer = position;
          // updateHover(scene, userData);
        } else {
          scene.mousePointer = position;
          updateHover(scene);
        }
      }
    }

    function pointerDown(e) {
      let position = getPosition(e);

      if (e.pointerType === "touch") {
        let id = e.pointerId;
        if (!pointers.includes(id)) {
          pointers.push({
            id: e.pointerId,
            position: position,
          });
        }
        let idx = pointers.map((o) => o.id).indexOf(e.pointerId);
        if (idx === 0) {
          tapRef.current = true;
          setTimeout(() => {
            tapRef.current = false;
          }, 200);
          // updateHover(scene);
        } else if (idx < 0) {
          scene.mousePointer = position;
          updateHover(scene);
        }
      } else {
        if (e.which == 2 || e.ctrlKey) {
          scene.pointerMiddleDown = true;
        } else {
          pointerClick(
            scene,
            userData,
            context.isGameOver,
            context.isGameCancelled,
            context.imageKeyForGame,
            context.endGame
          );
          updateHover(scene);
        }
      }
      canvas.setPointerCapture(e.pointerId);
    }

    function pointerUp(e) {
      let id = e.pointerId;
      let idx = pointers.map((o) => o.id).indexOf(id);
      pointers.splice(idx, 1);

      scene.pointerMiddleDown = false;

      if (e.pointerType === "touch" && tapRef.current && idx === 0) {
        pointerClick(
          scene,
          userData,
          context.isGameOver,
          context.isGameCancelled,
          context.imageKeyForGame,
          context.endGame
        );
        tapRef.current = false;
      }

      canvas.releasePointerCapture(e.pointerId);
    }

    function mouseWheel(e) {
      e.preventDefault();
      let position = getPosition(e);
      let sign = e.deltaY < 0 ? -1 : 1;
      let mult = 1;
      let abs = Math.abs(e.deltaY);
      if (abs > 25) {
        mult = 1.125;
      } else {
        mult = 1 + 0.125 * (abs / 10);
      }
      if (e.ctrlKey) {
        // zoom with ctrl
        zoomCamera({ position, scene, sign, mult });
      } else if (e.shiftKey) {
        // change image draw size
        if (!scene.shiftScrollUsed) {
          scene.shiftScrollUsed = true;
        }
        // setDrawSize({ mult, sign });
      } else {
        // default scroll to pan
        panCamera({
          scene,
          diff: [-e.deltaX, -e.deltaY],
          updateHoverCallback: true,
        });
      }
      scene.mousePointer = position;
      updateHover(scene);
    }

    // gesture disable so pinch to zoom works on mac
    document.addEventListener("gesturestart", (e) => e.preventDefault(), {
      passive: false,
    });
    document.addEventListener("gesturechange", (e) => e.preventDefault(), {
      passive: false,
    });

    canvas.addEventListener("pointermove", pointerMove);
    canvas.addEventListener("pointerdown", pointerDown);
    canvas.addEventListener("pointerup", pointerUp);
    canvas.addEventListener("wheel", mouseWheel, { passive: false });
    return () => {
      canvas.removeEventListener("pointermove", pointerMove);
      canvas.removeEventListener("pointerdown", pointerDown);
      canvas.removeEventListener("pointerup", pointerUp);
      canvas.removeEventListener("wheel", mouseWheel);
    };
  }, [context.isGameOver, context.endGame, userData]);

  return null;
}

export default Pointer;
