import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { aClicked, aUserData } from './State.js';
import { prependS3UrlToKey } from '../client.js';

/**
 * Modern browsers can download files that aren't from same origin this is a workaround to download a remote file
 * For more info: https://medium.com/charisol-community/downloading-resources-in-html5-a-download-may-not-work-as-expected-bf63546e2baa
 * @param `url` Remote URL for the file to be downloaded
 */
function Download({ url, filename }) {
  const handleClick = (event) => {
    event.preventDefault();

    if (!url) {
      throw new Error('Resource URL not provided! You need to provide one');
    }
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (filename && filename.length) a.download = filename;
        document.body.appendChild(a);
        a.click();
      });
  };

  return (
    <a onClick={handleClick} href={url} style={{ color: 'inherit', cursor: 'pointer' }}>
      download
    </a>
  );
}

function ClickedData() {
  let userData = useRecoilValue(aUserData);
  let [clicked, _] = useRecoilState(aClicked);

  let path, shortname;

  if (clicked !== null) {
    let f = userData.originalImages[clicked];
    let splits = f.split('/');
    shortname = splits[splits.length - 1];

    path = prependS3UrlToKey(f);
  }

  return clicked !== null ? (
    <div
      style={{
        position: 'fixed',
        right: 16,
        top: 24,
        background: 'rgba(50,50,50,0.9)',
        color: 'white',
        width: 260,
        padding: '8px 16px',
      }}
    >
      <div>Image {clicked}</div>
      <div style={{ wordBreak: 'break-word', fontSize: 14, lineHeight: 1.5 }}>{shortname}</div>
      <div>
        <Download url={path} filename={shortname} />
      </div>
    </div>
  ) : null;
}

export default ClickedData;
