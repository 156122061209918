import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import {
  aSize,
  aCamera,
  aDrawSize,
  aProfile,
  aUserData,
  aAtlasesLoaded,
} from "./State.js";
import Pointer from "./Pointer";
import Render from "./Render";
import ClickedData from "./ClickedData";
import Controls from "./Controls";
import { debug, zoomStart } from "./Constants";
import Keyboard from "./Keyboard";
import Uploads from "./Uploads";
import "./Font.css";
import "./Visualize.css";
import SimpleDialog from "../SimpleDialog.jsx";
import VisualizationContext from "../VisualizationContext.jsx";

let activeAuthentication = true;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  activeAuthentication = false;
}
function App({ userKey, branchName, lowResTextures }) {
  return (
    <RecoilRoot>
      <Inner
        userKey={userKey}
        branchName={branchName}
        lowResTextures={lowResTextures}
      />
    </RecoilRoot>
  );
}

export default App;

function Inner({ userKey, userData, branchName, lowResTextures, startGame }) {
  let canvas_ref = useRef(null);
  let [, setUserData] = useRecoilState(aUserData);
  let [profile, setProfile] = useRecoilState(aProfile);
  let [wwidth, wheight] = useRecoilValue(aSize);
  let atlasesLoaded = useRecoilValue(aAtlasesLoaded);
  const context = useContext(VisualizationContext);

  useEffect(() => {
    setUserData(context.userData);
    setProfile({ userKey });
  }, []);

  let scene_ref = useRef({
    projection: [],
    inverseProjection: [],
    view: [],
    viewProjection: [],
    inverseViewProjection: [],
    camera: [0, 0, zoomStart],
    draws: {},
    drawMeta: {},
    ray: [wwidth / 2, wheight / 2],
    oldRay: [0, 0],
    drawSize: 0.4,
    progress: 1,
    textureStore: {},
    showHover: 0,
    layout: 0,
    zoomAdjust: 1,
    mixCoords: 1,
    size: 256,
    lookups: {},
    textureSizes: [256],
    scroller: "blob",
    shiftScrollUsed: false,
    pointers: [],
    mousePointer: [0, 0],
    selectedTextureCheck: null,
    selectedStart: [0, 0, 0, -1],
    selectedEnd: [0, 0, 0, -1],
    selectedTransformed: [],
    transitionStartFrame: 0,
    transitionProgress: 1,
    selectTransitionStartFrame: 0,
    selectTransition: 1,
    distortionActive: 1,
    imageGrow: 1,
    hasImageGrowStarted: 0,
  });
  scene_ref.current.maxTextureSize = Math.max(
    ...scene_ref.current.textureSizes
  );

  return profile !== null ? (
    <React.Fragment>
      <SizeObserver />
      <Body
        scene_ref={scene_ref}
        canvas_ref={canvas_ref}
        startGame={startGame}
      />
      <Controls scene_ref={scene_ref} branchName={branchName} />
      <SizeObserver />
      <ClickedData />
      <Keyboard scene_ref={scene_ref} />
      <Cursor scene_ref={scene_ref} />
    </React.Fragment>
  ) : null;
}

function Cursor(scene_ref) {
  let cursorRef = useRef(null);
  let hideRef = useRef(false);
  let phoneRef = useRef(false);
  let atlasesLoaded = useRecoilValue(aAtlasesLoaded);
  let [IOS, setIOS] = useState(false);

  useEffect(() => {
    const cursor = cursorRef.current;
    document.body.style.overflow = "hidden";
    // document.body.style.cursor = "none";
    // cursor.style.backgroundImage =
    //   "url(" + process.env.PUBLIC_URL + "/images/cursor.png)";
    const pointerMove = (e) => {
      if (hideRef.current === false) {
        document.body.style.cursor = "none";
        hideRef.current = true;
        if (phoneRef.current !== null && phoneRef.current.style !== undefined) {
          phoneRef.current.style.display = "none";
        }
      }
      if (e.pointerType === "touch") {
        cursor.style.transform = `translate(${window.innerWidth / 2}px, ${
          window.innerHeight / 2
        }px)`;
      } else {
        cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
      }
    };

    let isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIOS(isIOS);

    document.addEventListener("pointermove", pointerMove);
    return () => {
      document.removeEventListener("pointermove", pointerMove);
    };
  }, []);
  return (
    <div>
      <div id="cursor" ref={cursorRef}></div>
      {atlasesLoaded && IOS ? (
        <div
          ref={phoneRef}
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        ></div>
      ) : null}
    </div>
  );
}

function SizeReference() {
  let sizes = [32, 64, 128, 256, 512];

  return (
    <div
      style={{
        position: "fixed",
        left: 16,
        top: 48,
        pointerEvents: "none",
      }}
    >
      {sizes.map((size) => {
        return (
          <div
            style={{
              width: size,
              height: size,
              background: "rgba(100,100,100,0.7)",
              marginBottom: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>{size}</div>
          </div>
        );
      })}
    </div>
  );
}

function Tweak({ scene_ref }) {
  let scene = scene_ref.current;
  let [hoverStartMagnify, _setHoverStartMagnify] = useState(
    scene.hoverStartMagnify
  );
  let [hoverEndMagnify, _setHoverEndMagnify] = useState(scene.hoverEndMagnify);
  let [hoverMaxMagnify, setHoverMaxMagnify] = useState(scene.hoverMaxMagnify);
  let [hoverMinMagnify, setHoverMinMagnify] = useState(scene.hoverMinMagnify);

  useEffect(() => {
    scene.hoverStartMagnify = hoverStartMagnify;
  }, [hoverStartMagnify]);
  useEffect(() => {
    scene.hoverEndMagnify = hoverEndMagnify;
  }, [hoverEndMagnify]);
  useEffect(() => {
    scene.hoverMaxMagnify = hoverMaxMagnify;
  }, [hoverMaxMagnify]);
  useEffect(() => {
    scene.hoverMinMagnify = hoverMinMagnify;
  }, [hoverMinMagnify]);

  function setHoverStartMagnify(val) {
    if (val < hoverEndMagnify) {
      _setHoverEndMagnify(val);
    }
    _setHoverStartMagnify(val);
  }
  function setHoverEndMagnify(val) {
    if (val > hoverStartMagnify) {
      _setHoverStartMagnify(val);
    }
    _setHoverEndMagnify(val);
  }

  return (
    <div
      style={{
        position: "fixed",
        right: 16,
        top: 16,
        background: "pink",
        color: "black",
        padding: 8,
        background: "rgba(200,200,200,0.8)",
        userSelect: "none",
      }}
    >
      <div>
        <label>startScaleDownZoomLevel:</label>
        <input
          type="range"
          min="0.1"
          max="10"
          step="0.1"
          value={hoverStartMagnify}
          onChange={(e) => {
            setHoverStartMagnify(parseFloat(e.target.value));
          }}
          style={{
            direction: "rtl",
          }}
        />{" "}
        {hoverStartMagnify.toFixed(1)}
      </div>
      <div>
        <label>finishScaleDownZoomLevel:</label>
        <input
          type="range"
          min="0.1"
          max="10"
          step="0.1"
          value={hoverEndMagnify}
          onChange={(e) => {
            setHoverEndMagnify(parseFloat(e.target.value));
          }}
          style={{
            direction: "rtl",
          }}
        />{" "}
        {hoverEndMagnify.toFixed(1)}
      </div>
      <div>
        <label>hoverMaxMagnify:</label>
        <input
          type="range"
          min="4"
          max="60"
          step="0.1"
          value={hoverMaxMagnify}
          onChange={(e) => {
            setHoverMaxMagnify(parseFloat(e.target.value));
          }}
        />{" "}
        {hoverMaxMagnify.toFixed(1)}
      </div>
      <div>
        <label>hoverMinMagnify:</label>
        <input
          type="range"
          min="1"
          max="4"
          step="0.1"
          value={hoverMinMagnify}
          onChange={(e) => {
            setHoverMinMagnify(parseFloat(e.target.value));
          }}
        />{" "}
        {hoverMinMagnify.toFixed(1)}
      </div>
    </div>
  );
}

function Body({ scene_ref, canvas_ref }) {
  let size = useRecoilValue(aSize);

  return (
    <div style={{ userSelect: "none" }}>
      {size[0] !== null ? (
        <Fragment>
          <Render canvas_ref={canvas_ref} scene_ref={scene_ref} />
          <Pointer canvas_ref={canvas_ref} scene_ref={scene_ref} />
          {debug ? <CameraRead /> : null}
        </Fragment>
      ) : null}
    </div>
  );
}

function CameraRead() {
  let camera = useRecoilValue(aCamera);
  let drawSize = useRecoilValue(aDrawSize);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 24,
        left: 24,
        display: "none",
      }}
    >
      ⌖ {camera.map((v) => v.toFixed(2)).join(", ")}
      <span style={{ marginLeft: 16 }}>{drawSize.toFixed(3)}</span>
    </div>
  );
}

function SizeObserver() {
  let [size, setSize] = useRecoilState(aSize);

  useEffect(() => {
    function setNewSize() {
      let w = window.innerWidth;
      let h = window.innerHeight;
      setSize([w, h]);
    }
    setNewSize();
    window.addEventListener("resize", setNewSize);
    return () => {
      window.removeEventListener("resize", setNewSize);
    };
  }, []);

  return null;
}
