import React, { useContext, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { aSize, aCamera, aDrawSize, aScroller, aUserData } from "./State.js";
import {
  perspective,
  invert,
  lookAt,
  getLookAt,
  multiply,
  getWorldFromPx,
} from "./Mat4.js";
import REGL from "regl";
import { zoomSize } from "./Constants";
import { getSizeKey } from "./Utils";
import Uploads from "./Uploads";
import { createSelectedDraw } from "./SelectedDraw";
import millisToMinutesAndSeconds from "../millisToMinutesAndSeconds.js";
import { prependS3UrlToKey } from "../client.js";
import VisualizationContext from "../VisualizationContext.jsx";
import { selectImage } from "./PointerUtils.js";
import { updateHover, pointerClick } from "./PointerUtils";
import handleFrameTransitions from "./HandleFrameTransitions";

const BUG_REPORT_URL = "https://forms.gle/GhevQscuqvUYgoXE6";

function Render({ canvas_ref, scene_ref }) {
  let size = useRecoilValue(aSize);
  let camera = useRecoilValue(aCamera);
  let drawSize = useRecoilValue(aDrawSize);
  let scroller = useRecoilValue(aScroller);
  let scene = scene_ref.current;
  let [loaded, setLoaded] = useState(false);

  const context = useContext(VisualizationContext);

  function setProjection(scene, size) {
    perspective(scene.projection, Math.PI / 3, size[0] / size[1], 0.001, 100);
    invert(scene.inverseProjection, scene.projection);
  }

  useEffect(() => {
    // REGL init
    let regl = REGL({
      canvas: canvas_ref.current,
      extensions: ["ANGLE_instanced_arrays"],
      optionalExtensions: [
        "WEBGL_compressed_texture_s3tc",
        "WEBGL_compressed_texture_pvrtc",
        "WEBGL_compressed_texture_etc1",
      ],
    });
    scene.gl = canvas_ref.current.getContext("webgl");
    scene.regl = regl;

    setProjection(scene, size);

    scene.tickStart = null;

    // duplicate state to avoid race condition should fix
    scene.width = window.innerWidth;
    scene.px = getWorldFromPx(scene, 1, zoomSize);

    scene.hoverCoord = [0, 0];
    scene.texSize = 0;
    scene.hoverTexCoords = [0, 0];
    scene.hoverTexture = scene.regl.texture();
    scene.showHover = false;

    // create selected draw
    scene.selectedTexture = scene.regl.texture();
    scene.selectedDraw = createSelectedDraw({ scene });

    setLoaded(true);

    // context loss debug
    // setTimeout(() => {
    //   scene.gl.getExtension('WEBGL_lose_context').loseContext();
    // }, 1000);

    let loop = regl.frame((props) => {
      try {
        let tick = props.tick;
        let sizeKey = getSizeKey(scene.size);

        let camera = scene.camera;
        lookAt(scene.view, camera, getLookAt(camera), [0, 1, 0]);
        multiply(scene.viewProjection, scene.projection, scene.view);
        invert(scene.inverseViewProjection, scene.viewProjection);

        let zoomAdjust = 1;
        scene.zoomAdjust = zoomAdjust;

        regl.clear({
          color: [0, 0, 0, 0],
        });

        handleFrameTransitions(scene, tick);

        // draw selected
        if (
          scene.selectedEnd[3] !== -1 &&
          scene.selectTransition === 1 &&
          scene.selectedFullsizeLoaded
        ) {
          // active selection and seletion transition is finished && texture is loaded
          scene.selectedDraw({
            viewProjection: scene.viewProjection,
            size: scene.drawSize,
            coord: scene.selectedEnd.slice(0, 2),
            aspect: scene.selectedAspect,
            selectedStart: scene.selectedStart.slice(0, 2),
            selectedEnd: scene.selectedEnd.slice(0, 2),
            texture: scene.selectedTexture,
            coordTransition: scene.transitionProgress,
          });
        }

        // draw main images
        let activeDraws = scene.draws[sizeKey] || [];
        let activeMeta = scene.drawMeta[sizeKey] || [];
        for (let i = 0; i < activeDraws.length; i++) {
          let draw = activeDraws[i];

          if (draw !== undefined) {
            draw({
              viewProjection: scene.viewProjection,

              // Multiply drawSize by imageGrow on viz open
              size:
                scene.drawSize * scene.imageGrow * scene.hasImageGrowStarted,
              startCoords: activeMeta[i].startCoordsBuffer,
              endCoords: activeMeta[i].endCoordsBuffer,
              coordTransition: scene.transitionProgress,
              zoomScale: zoomAdjust,
              texSize: scene.texSize,
              ray: scene.ray,
              selectedStart: scene.selectedStart,
              selectedEnd: scene.selectedEnd,
              selectTransition: scene.selectTransition,
              distortionActive: scene.distortionActive,
            });
          }
        }
      } catch (error) {
        console.log("error caught");
        console.log(error);
        loop.cancel();
      }
    });

    canvas_ref.current.addEventListener(
      "webglcontextlost",
      function (event) {
        loop.cancel();
        let div = document.createElement("div");
        div.innerText =
          "Something went wrong (context loss), please refresh the page";
        div.style =
          "position: fixed; left: 50%; margin-left: -240px; top: 20%; z-index; 999; background: #c55d5d; color: black; padding: 16px;";
        document.body.appendChild(div);
      },
      false
    );

    return function cleanup() {
      scene.regl.destroy();
    };
  }, []);

  // resize
  useEffect(() => {
    setProjection(scene, size);
  }, [size]);

  // scroller change
  useEffect(() => {
    scene.scroller = scroller;
  }, [scroller]);

  // shadow state
  useEffect(() => {
    scene.camera = camera;
    scene.width = size[0];
    scene.height = size[1];
    // scene.size = Math.max(...scene.textureSizes);
    // if (scene.camera[2] < 0.8) {
    //   scene.size = Math.max(...scene.textureSizes);
    // } else {
    //   scene.size = 64;
    // }
  }, [size, camera]);

  useEffect(() => {
    scene.drawSize = drawSize;
  }, [drawSize]);

  return (
    <div>
      <canvas
        id="render"
        ref={canvas_ref}
        width={size[0]}
        height={size[1]}
        style={{ position: "relative" }}
        aria-label="A visualization of the books in the Drop Everything library."
        role="img"
      ></canvas>
      {/* Temporary scroll instructions */}
      <div
        style={{
          color: "inherit",
          textDecoration: "none",
          textAlign: "center",
          pointerEvents: "auto",
          position: "absolute",
          left: 16,
          bottom: 16,
          background: "rgba(100,100,100,0.5)",
          display: "none",
        }}
        // Open Google Form for bug reports
        onClick={() => window.open(BUG_REPORT_URL, "_blank")}
      >
        <div
          className="controlButton"
          style={{
            padding: "6px 16px 8px 16px",
          }}
        >
          Report bug
        </div>
      </div>
      <div
        style={{
          color: "inherit",
          width: 500,
          textDecoration: "none",
          textAlign: "center",
          position: "absolute",
          left: 16,
          top: 50,
          background: "rgba(100,100,100,0.5)",
        }}
      >
        {context.isFindTheImageGame && (
          <div
            style={{
              padding: "6px 16px 8px 16px",
            }}
          >
            <div>
              Welcome to the Find the Image Game! Explore and organize your Soot
              environment. When you are ready, start the stopwatch and look for
              the random image that appears. Try to find it and click on it as
              fast as you can!
            </div>
            <br />

            {context.isGameOver || context.isGameCancelled ? (
              <div>
                {context.imageKeyForGame && (
                  <div>
                    <img
                      style={{
                        width: 300,
                      }}
                      // This next attribute is necessary to prevent a VERY pesky bug
                      // that prevents the browser from successfully requesting this image
                      // again in the actual visualization
                      crossOrigin="anonymous"
                      src={prependS3UrlToKey(context.imageKeyForGame)}
                    />
                    <br />
                  </div>
                )}
                {context.isGameOver && "Nice work!"} Your time was:{" "}
                {millisToMinutesAndSeconds(context.timeElapsed)}.
                <span
                  style={{
                    textDecoration: "underline",
                    fontStyle: "italic",
                    cursor: "pointer",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Play again?
                </span>
              </div>
            ) : context.timeElapsed != null ? (
              <div>
                {context.imageKeyForGame && (
                  <div>
                    <img
                      style={{
                        width: 300,
                      }}
                      // This next attribute is necessary to prevent a VERY pesky bug
                      // that prevents the browser from successfully requesting this image
                      // again in the actual visualization
                      crossOrigin="anonymous"
                      src={prependS3UrlToKey(context.imageKeyForGame)}
                    />
                    <br />
                  </div>
                )}
                <div>{millisToMinutesAndSeconds(context.timeElapsed)}</div>

                <div
                  role="button"
                  className={"controlButton"}
                  style={{
                    padding: "6px 16px 8px 16px",
                    textAlign: "center",
                    pointerEvents: "auto",
                  }}
                  onClick={() => {
                    context.cancelGame();
                    console.log(context.imageIndexForGame);
                    selectImage(
                      scene,
                      context.imageIndexForGame,
                      context.userData,
                      context.isGameOver,
                      context.isGameCancelled,
                      context.imageKeyForGame,
                      context.endGame
                    );
                  }}
                >
                  Can't figure it out? See the answer!
                </div>
              </div>
            ) : (
              <div
                role="button"
                className={"controlButton"}
                style={{
                  padding: "6px 16px 8px 16px",
                  textAlign: "center",
                  pointerEvents: "auto",
                }}
                onClick={context.startGame}
              >
                Start stopwatch
              </div>
            )}
          </div>
        )}
      </div>
      {loaded ? <Uploads scene_ref={scene_ref} /> : null}
    </div>
  );
}

export default Render;
