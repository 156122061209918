import { useContext, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { aAtlasesLoaded, aUserData } from "./State.js";
import { createDraw } from "./Draw";
import { getSizeKey } from "./Utils";
import { prependS3UrlToKey } from "../client.js";
import { BasisTextureLoader } from "./BasisTextureLoader";
import VisualizationContext from "../VisualizationContext.jsx";

let atlasCounter = 0;
let atlasTotal = 0;

let formatLookup = {
  1023: "RGBAFormat",
  33776: "RGB_S3TC_DXT1_Format",
  33779: "RGBA_S3TC_DXT5_Format",
  35840: "RGB_PVRTC_4BPPV1_Format",
  35842: "RGBA_PVRTC_4BPPV1_Format",
  36196: "RGB_ETC1_Format",
  36492: "RGBA_BPTC_Format",
  37492: "RGB_ETC2_Format",
  37496: "RGBA_ETC2_EAC_Format",
  37808: "RGBA_ASTC_4x4_Format",
};

function createPlaceHolderCanvas() {
  let textureBack = document.createElement("canvas");
  textureBack.width = 1;
  textureBack.height = 1;
  let tx = textureBack.getContext("2d");
  return tx.canvas;
}

function loadImage(url, texture, setAtlasesLoaded) {
  var image = new Image();
  image.crossOrigin = "";
  image.onload = function () {
    atlasCounter++;
    texture(image);
    image = null;
    if (atlasCounter === atlasTotal) {
      console.log("all loaded");
      setAtlasesLoaded(true);
    }
  };
  image.src = prependS3UrlToKey(url);

  return image;
}

function loadBasisTexture(
  loader,
  url,
  texture,
  setAtlasesLoaded,
  setTransitionLoad,
  scene
) {
  loader.load(
    prependS3UrlToKey(url),
    function (basisTexture) {
      atlasCounter++;
      let formatString = formatLookup[basisTexture.format];

      let format;
      if (formatString.includes("S3TC")) {
        format = "rgb s3tc dxt1";
      } else if (formatString.includes("PVRTC")) {
        format = "rgb pvrtc 4bppv1";
      } else if (formatString.includes("ETC1")) {
        format = "rgb etc1";
      }

      console.log(format);
      texture({
        ...basisTexture.mipmaps[0],
        format: format,
      });
      if (atlasCounter === atlasTotal) {
        console.log("all loaded");
        setAtlasesLoaded(true);
        setTransitionLoad(true);
        scene.imageGrow = 0;
      }
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );
}

function Uploads({ scene_ref, canvas_ref }) {
  const context = useContext(VisualizationContext);
  let userData = useRecoilValue(aUserData);
  let setAtlasesLoaded = useSetRecoilState(aAtlasesLoaded);
  let scene = scene_ref.current;

  // renderer check support for webgl
  let renderCheck = document.createElement("canvas").getContext("webgl");

  let placeholderCanvas = createPlaceHolderCanvas();
  scene.placeholderCanvas = placeholderCanvas;

  let spritesheetSize = 4096;

  const loader = new BasisTextureLoader();
  loader.setTranscoderPath(process.env.PUBLIC_URL + "/js/libs/basis/");
  loader.detectSupport(renderCheck);

  useEffect(() => {
    scene.draws = {};
    scene.drawMeta = {};

    for (let size of scene.textureSizes) {
      atlasTotal += userData.atlases[size].keys.length;
    }
    console.log("total atlases", atlasTotal);

    let umap2dCoords = userData.data.umap2dCoords;
    scene.coords = umap2dCoords;

    for (let size of scene.textureSizes) {
      let sizeKey = getSizeKey(size);

      let shortFilenames = userData.originalImages.slice();

      scene.lookups[sizeKey] = Array(umap2dCoords.length).fill(null);

      let atlas_urls = userData.atlases[size].keys;
      let atlas_coords = userData.atlases[size].coords;

      // preserve order
      scene.draws[sizeKey] = [];
      scene.drawMeta[sizeKey] = [];

      for (let i = 0; i < atlas_urls.length; i++) {
        let atlasUrl = atlas_urls[i];
        fetch(prependS3UrlToKey(atlas_coords[i]))
          .then((response) => response.json())
          .then((frames) => {
            let texture = scene.regl.texture(placeholderCanvas);

            let fileKeys = Object.keys(frames);

            let atlasInBranch = shortFilenames.indexOf(fileKeys[0]) > -1;

            if (!atlasInBranch) {
              // handle atlases that are not in branch
              atlasTotal -= 1;
            } else {
              let num = fileKeys.length;
              let indexes = fileKeys.map((f) => {
                let index = shortFilenames.indexOf(f);
                return index;
              });

              // make buffers for draw call
              let blankArray = [...Array(fileKeys.length)];
              let texCoords = blankArray.slice();
              let aspects = blankArray.slice();
              let texSize = blankArray.slice();

              for (let j = 0; j < num; j++) {
                let fileKey = fileKeys[j];
                let frame = frames[fileKey];
                let texCoord = [
                  frame[0] / spritesheetSize,
                  (spritesheetSize - frame[3] - frame[1]) / spritesheetSize,
                ];
                let aspect = [frame[2] / size, frame[3] / size];
                let texS = [
                  frame[2] / spritesheetSize,
                  frame[3] / spritesheetSize,
                ];

                texCoords[j] = texCoord;
                aspects[j] = aspect;
                texSize[j] = texS;

                let index = indexes[j];
                scene.lookups[sizeKey][index] = [
                  scene.drawMeta[sizeKey].length,
                  j,
                ];
              }

              // coordinates for draw call
              let offset2d = indexes.map((index) => {
                return umap2dCoords[index];
              });

              scene.drawMeta[sizeKey].push({
                keys: fileKeys,
                indexes,
                coords: offset2d,
                startCoordsBuffer: scene.regl.buffer(offset2d),
                endCoordsBuffer: scene.regl.buffer(offset2d),
                texCoords,
                aspects,
                texSize,
                texture,
              });

              scene.draws[sizeKey].push(
                createDraw({
                  num,
                  scene,
                  aspects,
                  indexes,
                  texCoords,
                  texSize,
                  texture,
                  indexes,
                })
              );
              // loadImage(atlasUrl, texture, setAtlasesLoaded);
              loadBasisTexture(
                loader,
                atlasUrl,
                texture,
                setAtlasesLoaded,
                context.setTransitionLoad,
                scene
              );
            }
          });
      }
    }
  }, []);

  return null;
}

export default Uploads;
