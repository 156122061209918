import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      opacity: 0.6,
    },
  },
});

function StyledIconButton({ isDisplayed, handleClick, IconComponent }) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.root}
      style={{
        color: 'white',
        display: isDisplayed ? 'block' : 'none',
      }}
      onClick={handleClick}
    >
      <IconComponent />
    </IconButton>
  );
}

export default StyledIconButton;
