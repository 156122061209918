// Navbar for the Directory and Visualization views

import { Button, Grid, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { firebaseAuth } from './firebase';

import {
  Dashboard as DashboardIcon,
  Apps as AppsIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      opacity: 0.6,
    },
  },
});

function StyledLink({ children, ...rest }) {
  const classes = useStyles();

  return (
    <Link
      className={classes.root}
      {...rest}
      style={{
        textDecoration: 'none',
      }}
    >
      {children}
    </Link>
  );
}

function Navbar() {
  const classes = useStyles();
  const { handleSignout } = useContext(firebaseAuth);

  return (
    <Grid
      container
      justify="center"
      spacing={4}
      style={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
      }}
    >
      <Grid item>
        <StyledLink to={'/directory'}>
          <Button style={{ color: 'white' }} startIcon={<AppsIcon />}>
            Directory
          </Button>
        </StyledLink>
      </Grid>
      <Grid item>
        <StyledLink to={'/upload'}>
          <Button style={{ color: 'white' }} startIcon={<DashboardIcon />}>
            Upload
          </Button>
        </StyledLink>
      </Grid>
      <Grid item>
        <StyledLink to={'/query'}>
          <Button style={{ color: 'white' }} startIcon={<DashboardIcon />}>
            Query
          </Button>
        </StyledLink>
      </Grid>
      <Grid item>
        <Button
          className={classes.root}
          style={{ color: 'white' }}
          onClick={handleSignout}
          startIcon={<ExitToAppIcon />}
        >
          Sign out
        </Button>
      </Grid>
    </Grid>
  );
}

export default Navbar;
