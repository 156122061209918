// Container for all the links to profiles. All of the grid-like
// structural components are in this file, so you don't need to
// go into the lower-level components to manipulate structure.

import { Grid, GridList, GridListTile } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import {
  deleteBranch,
  downloadAllImagesInBranch,
  getPublicBranches,
  getUserBranches,
  toggleBranchPublicEdit,
  updateBranchName,
} from './client';
import branchIsReady from './branchIsReady';
import { firebaseAuth } from './firebase';

import Profile from './Profile';
import DisabledProfile from './DisabledProfile';
import useInterval from './useInterval';
import EditNameDialog from './EditNameDialog';
import DeleteDialog from './DeleteDialog';
import DownloadDialog from './DownloadDialog';
import PropertiesDialog from './PropertiesDialog';

function Directory() {
  const { currentUserKey } = useContext(firebaseAuth);

  const [selectedBranch, setSelectedBranch] = useState();
  const [displayedBranches, setDisplayedBranches] = useState([]);

  const [editNameText, setEditNameText] = useState('');
  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = useState(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);

  const [isPermissionChangeLoading, setIsPermissionChangeLoading] = useState(false);
  const [isPropertiesDialogOpen, setIsPropertiesDialogOpen] = useState(false);

  // Helper function that gets the most recent branches
  // from the server and updates local state
  const _getBranches = async () => {
    try {
      // Get only the branches that are ready for this user
      const newUserBranches = await getUserBranches(currentUserKey);

      // Get all public branches
      const newPublicBranches = await getPublicBranches();

      setDisplayedBranches([
        // All public branches ("ready" by default)
        ...newPublicBranches,

        // User branches that aren't ready
        ...newUserBranches.filter((branch) => !branchIsReady(branch)),

        // User branches that are ready but are still not public
        ...newUserBranches.filter(
          (branch) => branchIsReady(branch) && !branch.branchInfo.permissions.public.edit,
        ),
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  // Updates branches on component mount
  useEffect(() => {
    _getBranches();
  }, []);

  // Polling this endpoint every 5 seconds to
  // update branches
  useInterval(_getBranches, 5000);

  // Handlers to edit branch name
  const handleEditNameTextChange = (event) => setEditNameText(event.target.value);
  const handleEditNameDialogOpen = (branch) => {
    setSelectedBranch(branch);
    setEditNameText(branch.branchInfo.branchName);
    setIsEditNameDialogOpen(true);
  };
  const handleEditNameDialogClose = () => {
    setIsEditNameDialogOpen(false);
    setEditNameText('');
    setSelectedBranch();
  };
  const handleEditNameSubmit = async () => {
    try {
      await updateBranchName(selectedBranch.key, editNameText);

      // After the API request to update the name is complete,
      // reload the user branch data
      await _getBranches();
    } catch (err) {
      console.log(err);
      alert('Error! Please contact developers.');
    }
    handleEditNameDialogClose();
  };

  // Handlers to delete branch
  const handleDeleteDialogOpen = (branch) => {
    setSelectedBranch(branch);
    setIsDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setIsDeleteDialogOpen(false);
  };
  const handleDelete = async () => {
    setIsDeleteLoading(true);
    try {
      await deleteBranch(selectedBranch.key);

      // After the API request to update the name is complete,
      // reload the user branch data
      await _getBranches();
    } catch (err) {
      console.log(err);
      alert('Error! Please contact developers.');
    }
    handleDeleteDialogClose();
    setIsDeleteLoading(false);
  };

  // Handlers to open/close properties dialog
  const handlePropertiesDialogOpen = (branch) => {
    setSelectedBranch(branch);
    setIsPropertiesDialogOpen(true);
  };
  const handlePropertiesDialogClose = () => {
    setIsPropertiesDialogOpen(false);
  };

  // Handler to change user permissions settings
  const handleUserPermissionsChange = async (branch) => {
    try {
      // update the checkbox loading state to indicate that the
      // checkbox corresponding to this branch is now loading
      setIsPermissionChangeLoading(true);

      await toggleBranchPublicEdit(branch);

      // After the API request to update the name is complete,
      // reload the user branch data
      await _getBranches();

      setIsPermissionChangeLoading(false);
      setSelectedBranch({
        ...selectedBranch,
        branchInfo: {
          ...selectedBranch.branchInfo,
          permissions: {
            ...selectedBranch.branchInfo.permissions,
            public: {
              ...selectedBranch.branchInfo.permissions.public,
              edit: !selectedBranch.branchInfo.permissions.public.edit,
            },
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Handler to download branch
  const handleDownload = async (branch) => {
    setIsDownloadDialogOpen(true);
    try {
      await downloadAllImagesInBranch(branch.key, branch.branchInfo.branchName);
    } catch (err) {
      console.log(err);
    }
    setIsDownloadDialogOpen(false);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          padding: 10,
        }}
      >
        <Grid item>
          <GridList cellHeight={300} cols={3}>
            {displayedBranches.map((branch) => (
              <GridListTile key={branch.key}>
                {branchIsReady(branch) ? (
                  <Profile
                    branch={branch}
                    isOwnedByCurrentUser={branch.ownerKey == currentUserKey}
                    handleDownload={handleDownload}
                    handleDeleteDialogOpen={handleDeleteDialogOpen}
                    handleEditNameDialogOpen={handleEditNameDialogOpen}
                    handlePropertiesDialogOpen={handlePropertiesDialogOpen}
                  />
                ) : (
                  <DisabledProfile branch={branch} />
                )}
              </GridListTile>
            ))}
          </GridList>
        </Grid>
      </Grid>
      <EditNameDialog
        isEditNameDialogOpen={isEditNameDialogOpen}
        handleEditNameDialogClose={handleEditNameDialogClose}
        handleEditNameTextChange={handleEditNameTextChange}
        editNameText={editNameText}
        handleEditNameSubmit={handleEditNameSubmit}
      />
      <DeleteDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleDeleteDialogClose={handleDeleteDialogClose}
        isDeleteLoading={isDeleteLoading}
        handleDelete={handleDelete}
      />
      <DownloadDialog isDownloadDialogOpen={isDownloadDialogOpen} />
      <PropertiesDialog
        isPropertiesDialogOpen={isPropertiesDialogOpen}
        handlePropertiesDialogClose={handlePropertiesDialogClose}
        selectedBranch={selectedBranch}
        isPermissionChangeLoading={isPermissionChangeLoading}
        handleUserPermissionsChange={handleUserPermissionsChange}
      />
    </>
  );
}

export default Directory;
