import { Grid, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import userUploadStatus from './userUploadStatus';

import { Public as PublicIcon } from '@material-ui/icons';

import defaultImage from './images/icon.png';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      opacity: 0.6,
    },
  },
});

function DisabledProfile({ branch }) {
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const classes = useStyles();

  return (
    <div onMouseOver={() => setMouseIsOver(true)} onMouseOut={() => setMouseIsOver(false)}>
      <div
        style={{
          textAlign: 'center',
          display: 'block',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <h2
              style={{
                color: 'gray',
              }}
            >
              {branch.branchInfo.branchName}
            </h2>
          </Grid>
          <Grid item style={{ paddingTop: '28px' }}>
            {branch.branchInfo.permissions.public.edit ? <PublicIcon /> : null}
          </Grid>
        </Grid>
        <img
          src={defaultImage}
          style={{
            maxHeight: '100px',
            filter: 'gray' /* IE6-9 */,
            WebkitFilter: 'grayscale(1)' /* Google Chrome, Safari 6+ & Opera 15+ */,
            filter: 'grayscale(1)' /* Microsoft Edge and Firefox 35+ */,
          }}
        />
      </div>
      <Grid container justify="center" spacing={3}>
        <div
          className={classes.root}
          style={{
            paddingTop: '15px',
            color: 'white',
            display: mouseIsOver ? 'block' : 'none',
          }}
        >
          {userUploadStatus[branch.branchInfo.status] &&
            userUploadStatus[branch.branchInfo.status].displayText}
        </div>
      </Grid>
    </div>
  );
}

export default DisabledProfile;
