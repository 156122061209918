function handleFrameTransitions(scene, tick) {
  // transition between coordinates
  let duration = 40;
  if (scene.transitionProgress === 0) {
    scene.transitionStartFrame = tick;
    scene.transitionProgress = 1 / duration;
  } else if (scene.transitionProgress < 1) {
    let relTick = tick - scene.transitionStartFrame;
    scene.transitionProgress = relTick / duration;
  } else {
    scene.transitionProgress = 1;
  }

  // transition into select
  duration = 10;
  if (scene.selectTransition === 0) {
    scene.selectTransitionStartFrame = tick;
    scene.selectTransition = 1 / duration;
  } else if (scene.selectTransition < 1) {
    let relTick = tick - scene.selectTransitionStartFrame;
    scene.selectTransition = relTick / duration;
  } else {
    scene.selectTransition = 1;
  }

  // transition for image grow
  duration = 40;
  if (scene.imageGrow === 0) {
    scene.imageGrowStartFrame = tick;
    scene.imageGrow = 1 / duration;
    scene.hasImageGrowStarted = 1;
  } else if (scene.imageGrow < 1) {
    let relTick = tick - scene.imageGrowStartFrame;
    scene.imageGrow = relTick / duration;
  } else {
    scene.imageGrow = 1;
  }
}

export default handleFrameTransitions;
