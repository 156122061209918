export let version = 1.3;
export let zoomSize = 20;
export let debug = true;
export let layoutNames = ["umap2dCoords", "gridCoords", "umap3dCoords"];
export let initDrawSize = 0.2;

// fisheye settings
export let zoomStart = 22;
export let curveInit = {
  curvePointA: [0.0001, 0.0001],
  curvePointB: [7.0, 70.0],
  curvePointC: [200, 2],
  curveRadius: 100,
  maxMagnify: 200,
  spread: 4.0,
};
// grant placeholder original
// export let zoomStart = 10;
// export let curveInit = {
//   curvePointA: [0.0001, 0.0001],
//   curvePointB: [2.0, 6.0],
//   curvePointC: [12, 12],
//   curveRadius: 12,
//   maxMagnify: 2.8,
//   spread: 1.4,
// };
